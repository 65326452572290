import styled from "styled-components";
import colors from "../../styles/colors";

interface CardContainerProps{
    width?: number;
    height?: number;
}

export const CardContainer = styled.div<CardContainerProps>`
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 8px ${colors.darkGrey};
    min-width: ${(props: CardContainerProps) => props.width || "250px"};
    height: ${(props: CardContainerProps) => props.height || "110px"};
    border-radius: 8px;
    overflow: hidden;
    margin: 8px 4px 8px 4px;
`;

export const CardHeader = styled.div`
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    background-color: ${colors.blueOfficial};
    color: #fff;
    padding: 2px 4px 2px 4px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;