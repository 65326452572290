import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

import brasaoRN from "../../../../template/assets/logo/logo_estado.png";
import { parseData } from "../../../../template/utils/parser";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    marginTop: 5,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

type DataTableValue = {
  [k: string]: string;
};
interface IProps {
  data: DataTableValue[];
  count: number;
  municipio: string;
  pontoNome: string;
  selectedPonto: any;
  pontos: any[];
  totalMunicipio: number;
  startPage: number;
  endPage: number;
}

const DocumentPDF = ({
  data,
  municipio,
  pontoNome,
  selectedPonto,
  pontos,
  count,
  totalMunicipio,
  startPage,
  endPage,
}: IProps) => {
  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${AddLeadingZeros(temp.getUTCDate())}/${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}/${temp.getUTCFullYear()}`;
    return formated;
  };

  return (
    <>
      <Document title="Lista de famílias vinculadas">
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.image} source={brasaoRN} />
            <View style={styles.headerTextSection}>
              <Text style={styles.headerText}>
                GOVERNO DO ESTADO DO RIO GRANDE DO NORTE
              </Text>
              <Text style={styles.headerText}>
                SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
                SOCIAL - SETHAS
              </Text>
              <Text style={styles.headerText}>PROGRAMA DO LEITE POTIGUAR</Text>
            </View>
            <View style={styles.titleTextSection}>
              <Text style={styles.titleText}>
                Lista de famílias vinculadas{" "}
                {selectedPonto == 0 ? "aos pontos" : `ao ponto ${pontoNome}`} do
                município de {municipio} referentes à paginação {startPage} até{" "}
                {endPage}
              </Text>
            </View>
          </View>
          {selectedPonto == 0 ? (
            pontos.map((ponto) => {
              let familias = ponto.familias;
              return (
                <>
                  <View style={styles.titleTextSection}>
                    <Text style={styles.titleText}>{ponto.nome}</Text>
                  </View>
                  <Table data={[...familias]}>
                    <TableHeader>
                      <TableCell style={styles.cell}>Responsável</TableCell>
                      <TableCell style={styles.cell}>Código familiar</TableCell>
                      <TableCell style={styles.cell}>
                        Renda per capita
                      </TableCell>
                      <TableCell style={styles.cell}>
                        Tipo de participante
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={styles.cellData}
                        getContent={(r) => r.responsavel}
                      />
                      <DataTableCell
                        style={styles.cellData}
                        getContent={(r) => r.cod_familia}
                      />
                      <DataTableCell
                        style={styles.cellData}
                        getContent={(r) =>
                          r.renda.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        }
                      />
                      <DataTableCell
                        style={styles.cellData}
                        getContent={(r) => r.tipo}
                      />
                    </TableBody>
                  </Table>
                </>
              );
            })
          ) : (
            <>
              <Table data={[...data]}>
                <TableHeader>
                  <TableCell style={styles.cell}>Responsável</TableCell>
                  <TableCell style={styles.cell}>Código familiar</TableCell>
                  <TableCell style={styles.cell}>Renda per capita</TableCell>
                  <TableCell style={styles.cell}>
                    Tipo de participante
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell
                    style={styles.cellData}
                    getContent={(r) => r.responsavel}
                  />
                  <DataTableCell
                    style={styles.cellData}
                    getContent={(r) => r.cod_familia}
                  />
                  <DataTableCell
                    style={styles.cellData}
                    getContent={(r) => r.renda}
                  />
                  <DataTableCell
                    style={styles.cellData}
                    getContent={(r) => r.tipo}
                  />
                </TableBody>
              </Table>
            </>
          )}

          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <Table data={[3]}>
            <TableHeader>
              <TableCell style={styles.cell}>Município</TableCell>
              {selectedPonto > 0 ? (
                <TableCell style={styles.cell}>
                  Total famílias vinculadas ao ponto
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell style={styles.cell}>
                Total famílias vinculadas ao município
              </TableCell>
            </TableHeader>
            <TableBody>
              <TableCell style={styles.cell}>{municipio}</TableCell>
              {selectedPonto > 0 ? (
                <TableCell style={styles.cell}>{count}</TableCell>
              ) : (
                <></>
              )}
              <TableCell style={styles.cell}>{totalMunicipio}</TableCell>
            </TableBody>
          </Table>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.currentDate}>
            <Text style={styles.MealsCountText}>
              Documento gerado em {parseData(new Date().toUTCString())}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default DocumentPDF;
