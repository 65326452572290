import styled from "styled-components";
import colors from "../../template/styles/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: gray;
`;

export const HomeContent = styled.div`
  width: 100%;
  background: white;
  padding: 10px;
  border: 1px solid ${colors.darkGrey};
  border-radius: 8px;
`;
