import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

import brasaoRN from "../../../../../template/assets/logo/logo_estado.png";
import {
  LaticinioInterface,
  PontoInterface,
} from "../../../../../template/utils/types";
import { parseData } from "../../../../../template/utils/parser";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    marginTop: 5,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

type DataTableValue = {
  [k: string]: string;
};
interface IProps {
  data: DataTableValue[];
  ponto: any;
  municipio: any;
  count: number;
  dateStart: string;
  dateEnd: string;
  totalBovino: number;
  totalCaprino: number;
  agregado: boolean;
}

const DocumentPDF = ({
  data,
  ponto,
  municipio,
  count,
  dateStart,
  dateEnd,
  totalBovino,
  totalCaprino,
  agregado,
}: IProps) => {
  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${AddLeadingZeros(temp.getUTCDate())}/${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}/${temp.getUTCFullYear()}`;
    return formated;
  };

  return (
    <>
      <Document title="Relatório de Distribuições">
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.image} source={brasaoRN} />
            <View style={styles.headerTextSection}>
              <Text style={styles.headerText}>
                GOVERNO DO ESTADO DO RIO GRANDE DO NORTE
              </Text>
              <Text style={styles.headerText}>
                SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
                SOCIAL - SETHAS
              </Text>
              <Text style={styles.headerText}>PROGRAMA DO LEITE POTIGUAR</Text>
            </View>
            <View style={styles.titleTextSection}>
              {agregado ? (
                <Text style={styles.titleText}>
                  RELATÓRIO DE DISTRIBUIÇÕES DO LEITE AGRUPADAS POR FAMÍLIA
                </Text>
              ) : (
                <Text style={styles.titleText}>
                  RELATÓRIO DE DISTRIBUIÇÕES DO LEITE
                </Text>
              )}
            </View>
            {municipio ? (
              <View>
                <Text style={styles.titleText}>
                  MUNICÍPIO {municipio?.nome.toUpperCase()}
                </Text>
              </View>
            ) : (
              <View>
                <Text style={styles.titleText}>TODOS OS MUNICÍPIOS</Text>
              </View>
            )}
          </View>
          {ponto ? (
            <>
              <View>
                <Text style={styles.MealsCountText}>
                  <Text style={styles.titleText}>Ponto:</Text> {ponto?.nome}
                </Text>
              </View>
              <View>
                <Text style={styles.MealsCountText}>
                  <Text style={styles.titleText}>Laticínio:</Text>{" "}
                  {ponto?.laticinio.nome}
                </Text>
              </View>
            </>
          ) : (
            <></>
          )}
          <View style={styles.MealsCountSection}>
            {agregado ? (
              <Text style={styles.MealsCountText}>
                Existe um total de {data.length} famílias amparadas no período
                de {dateStart + " até " + dateEnd}
              </Text>
            ) : (
              <Text style={styles.MealsCountText}>
                Existe um total de {count} distribuições no período de{" "}
                {dateStart + " até " + dateEnd}
              </Text>
            )}
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <Table data={[...data]}>
            <TableHeader>
              <TableCell style={styles.cell}>Responsável</TableCell>
              <TableCell style={styles.cell}>Código da família</TableCell>
              <TableCell style={styles.cell}>Qtd Bovino</TableCell>
              <TableCell style={styles.cell}>Qtd Caprino</TableCell>
              <TableCell style={styles.cell}>Qtd total</TableCell>
              <TableCell style={styles.cell}>Tipo</TableCell>
              {agregado ? (
                <TableCell style={styles.cell}>Qtd Distribuições</TableCell>
              ) : (
                <TableCell style={styles.cell}>Data</TableCell>
              )}
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.responsavel.nome}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.cod_familia}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.quantidade_leite_bovino}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.quantidade_leite_caprino}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) =>
                  r.quantidade_leite_bovino + r.quantidade_leite_caprino
                }
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.tipo}
              />
              {agregado ? (
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => r.quantidade_distribuicoes}
                />
              ) : (
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => parseData(r.data)}
                />
              )}
            </TableBody>
          </Table>

          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>

          <Table data={[3]}>
            <TableHeader>
              <TableCell style={styles.cell}>
                Total de leite Bovino distribuído
              </TableCell>
              <TableCell style={styles.cell}>
                Total de leite Caprino distribuído
              </TableCell>
              <TableCell style={styles.cell}>
                Total de leite distribuído
              </TableCell>
            </TableHeader>
            <TableBody>
              <TableCell style={styles.cell}>{totalBovino}</TableCell>
              <TableCell style={styles.cell}>{totalCaprino}</TableCell>
              <TableCell style={styles.cell}>
                {totalCaprino + totalBovino}
              </TableCell>
            </TableBody>
          </Table>

          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.currentDate}>
            <Text style={styles.MealsCountText}>
              Relatório gerado em {parseData(new Date().toUTCString())}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default DocumentPDF;
