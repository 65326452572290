import styled from "styled-components";
import colors from "../../../../template/styles/colors";

export const CardLine = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.lightGrey};
  flex: 1;
  padding-left: 4px;
  p {
    margin: 0;
  }
  span {
    font-weight: bold;
  }
`;
