import styled from "styled-components";
import colors from "../../../../../template/styles/colors";
import arrow from "../../../../../template/assets/icons/arrow.png";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }

  input:read-only {
    background: ${colors.lightGrey};
    ::placeholder {
      color: ${colors.lightGrey};
    }
  }

  select:disabled {
    font-weight: bold;
    color: black;
    background: ${colors.lightGrey};
  }

  .statusParticipanteDesabilitado {
    color: ${colors.redError};
    border: 1px solid ${colors.redError} !important;
    border-radius: 5px;
  }

  .editarParticipante {
    padding: 5px;
    background-color: white;
    color: ${colors.blueOfficial};
    margin-left: 10px;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      border: 1px solid ${colors.bluePLP};
      color: ${colors.bluePLP};
    }
  }

  .CustomSelect {
    height: 39px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid #c8ced3;
    /* border: 1px solid${colors.bluePLP}; */
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 35px 8px 8px;
    background: url(${arrow}) 95% / 10px no-repeat;
    background-color: ${colors.light};
    width: 100%;
  }

  .list {
    list-style-type: none;
    font-size: 14px;
  }

  .list li {
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    height: 45px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: space-between;
  }

  .excluirParticipante {
    padding: 5px;
    background-color: white;
    color: ${colors.redError};
    margin-left: 10px;
    border: 1px solid ${colors.redError};
    border-radius: 5px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      border: 1px solid darkred;
      color: darkred;
    }
  }

  .TextEditarParticipante {
    margin-left: 5px;
  }

  .acaoParticipante {
    display: flex;
    margin-right: 5px;
  }
`;
