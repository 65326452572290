import styled from "styled-components";
import colors from "../../../../template/styles/colors";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const genericButton = `
    padding: 8px 20px;
    border-radius: 2px;
`;

export const ButtonSave = styled.button`
	${genericButton} background: ${colors.greenOfficial};
	border: none;
	color: #fff;
`;

export const ButtonClose = styled.button`
  ${genericButton};
  border: 1px solid ${colors.mediumGrey};
`;

const genericIcon = `
    font-size: 15px;
    margin-right: 3px;
`;

export const CustomAiOutlineCheck = styled(AiOutlineCheck)`
  ${genericIcon}
`;

export const CustomAiOutlineClose = styled(AiOutlineClose)`
  ${genericIcon}
`;
