import styled from "styled-components";
import colors from "../../../template/styles/colors";
import { Spinner } from "reactstrap";
import Select from "react-select";

export const Container = styled.div`
  .row {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .space {
    margin-left: 20px;
  }
  .containerFooterTable {
    display: flex;
    justify-content: space-between;
  }

  .botoes {
    display: flex;
    align-items: center;
    column-count: 3;
    column-gap: 15px;
  }

  .headerContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
  }

  .headerSearchBar {
    display: flex;
    flex-direction: row;
  }

  .itemsSelectHeader {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
  }

  label {
    font-weight: bolder;
    font-size: 13px;
    color: ${colors.bluePLP};
  }

  .CheckBoxContainer {
    display: flex;
    margin-top: 40px;
    height: 39px;
    align-items: center;
  }
`;

export const DateContainer = styled.div`
  display: flex;

  input {
    height: 39px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid #c8ced3;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 8px 8px 8px;
    text-align: right;
    background-color: ${colors.light};
  }
`;

export const CustomSpinner = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 2px;
  margin-right: 2px;
`;

export const CustomReactSelect = styled(Select)`
  min-width: 200px;
`;
