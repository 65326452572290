import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RiPagesLine } from "react-icons/ri";
import Layout from "../../../template/components/Layout";
import MainContainer from "../../../template/components/MainContainer";
import Pagination from "../../../template/components/Pagination";
import Table from "../../../template/components/Table";
import { dialogBox } from "../../../template/utils/dialogBox";
import { PontoInterface } from "../../../template/utils/types";
import {
  Container,
  CustomReactSelect,
  CustomSpinner,
  DateContainer,
} from "./styles";
import { createFilter } from "react-select";
import api from "../../../services/api";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import { MdPictureAsPdf } from "react-icons/md";
import Button from "../../../template/components/Button";
import { pdf } from "@react-pdf/renderer";
import DocumentPDF from "./components/DocumentPDF";
import { saveAs } from "file-saver";
import { getUserToken } from "../../../services/auth";
import { json2csv } from "json-2-csv";
import { FaFileCsv } from "react-icons/fa";

const RelatorioEntregasLaticinio = () => {
  // Referente aos selects
  const [laticinios, setLaticinios] = useState<any[]>([]);
  const [laticinioSelected, setLaticinioSelected] = useState<number>(0);
  const [pontos, setPontos] = useState<PontoInterface[]>([]);
  const [pontoSelected, setPontoSelected] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<any>({
    value: 0,
    label: "Todos",
  });

  // Lista as opções nos selects de laticinios e pontos
  const [laticiniosOptions, setLaticiniosOptions] = useState<any>([]);
  const [pontosOptions, setPontosOptions] = useState<any>([]);
  const [totalBovino, setTotalBovino] = useState(0);
  const [totalCaprino, setTotalCaprino] = useState(0);

  // Referente as entregas
  const [dataInicial, setDataInicial] = useState<any>();
  const [dataFinal, setDataFinal] = useState<any>();
  const [entregas, setEntregas] = useState<any[]>([]);
  const [entregasReport, setEntregasReport] = useState<any[]>([]);

  // Referente a paginação
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const itemsPerPage = 10;

  const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false);

  const [csvCheck, setCsvCheck] = useState<boolean>(false);

  const user_is_gestor_estadual = getUserToken().is_gestor_estadual;

  const { register, errors, getValues } = useForm();

  //Titulo da tabela
  const fields = [
    "Laticinio",
    "Gestor do Laticínio",
    "Qtd Bovino",
    "Qtd Caprino",
    "Ponto de distribuição",
    "Data",
  ];

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    (async function () {
      try {
        if (!dataInicial || !dataFinal || laticinioSelected === 0) {
          return;
        }

        const { data } = await api.get(
          `entregas/?limit=${itemsPerPage}&offset=${offset}&laticinio=${laticinioSelected}&ponto=${
            pontoSelected > 0 ? pontoSelected : ""
          }&data_inicial=${dataInicial}&data_final=${dataFinal}`
        );
        const { count, results } = data;

        setTotal(count);

        setEntregas(
          parseJsonToTableDataList(results, [
            "laticinio",
            "gestor_laticinio",
            "quantidade_leite_bovino",
            "quantidade_leite_caprino",
            "ponto",
            "data",
          ])
        );

        calcTotalCotaBovinoCaprino(results);
      } catch (err) {
        notify("error", "Ocorreu um erro ao obter os dados das entregas");
      }
    })();
  }, [offset, total, laticinioSelected, pontoSelected, dataInicial, dataFinal]);

  const getEntregasReport = async () => {
    try {
      if (!dataInicial || !dataFinal || laticinioSelected === 0) {
        return;
      }

      const { data } = await api.get(
        `entregas/?laticinio=${laticinioSelected}&ponto=${
          pontoSelected > 0 ? pontoSelected : ""
        }&data_inicial=${dataInicial}&data_final=${dataFinal}`
      );
      setEntregasReport(data);
    } catch (err) {
      notify("error", "Ocorreu um erro ao obter os dados das entregas");
    }
  };

  useEffect(() => {
    if (user_is_gestor_estadual) {
      (async function () {
        try {
          const { data } = await api.get("laticinios/");
          setLaticinios(data);
        } catch (err) {
          notify("error", "Ocorreu um erro ao carregar laticínios");
        }
      })();
    }
  }, []);

  const calcTotalCotaBovinoCaprino = (results: any[]) => {
    let bovino = 0,
      caprino = 0;

    results.forEach((elem) => {
      bovino += elem.quantidade_leite_bovino;
      caprino += elem.quantidade_leite_caprino;
    });

    setTotalBovino(bovino);
    setTotalCaprino(caprino);
  };

  useEffect(() => {
    (async function () {
      try {
        if (laticinioSelected === 0) return;

        const { data } = await api.get(
          `pontos/?laticinio=${laticinioSelected}`
        );

        setPontos(data.results);
      } catch (err) {
        notify("error", "Ocorreu um erro ao carregar pontos");
      }
    })();
  }, [laticinioSelected]);

  useEffect(() => {
    if (entregasReport?.length !== 0)
      csvCheck === true ? createCSV() : createPdf();
  }, [entregasReport]);

  useEffect(() => {
    if (!user_is_gestor_estadual) {
      // carrega os laticínios referentes aos pontos ligados a entidade que o gestor local faz parte
      (async function () {
        const tempArrLat: any[] = [];

        try {
          const {
            data: { results },
          } = await api.get(`pontos/`);

          results.map((ponto: any) => {
            // evita carregar laticinios repetidos no select
            if (
              tempArrLat.findIndex((elem) => elem.id === ponto.laticinio.id) ===
              -1
            ) {
              tempArrLat.push(ponto.laticinio);
            }
          });

          setLaticinios(tempArrLat);
        } catch (err) {
          notify("error", "Ocorreu um erro ao carregar pontos");
        }
      })();
    }
  }, []);

  const createPdf = async () => {
    try {
      const blob = await pdf(
        <DocumentPDF
          data={entregasReport}
          count={entregasReport?.length}
          dateStart={dataInicial}
          dateEnd={dataFinal}
          totalBovino={totalBovino}
          totalCaprino={totalCaprino}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      saveAs(blob, `relatorio_entregas_por_laticinio_${datenow}.pdf`);
      setDownloadingPdf(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar pdf");
      setDownloadingPdf(false);
    }
  };

  function parseEntregasDocument(data: any[]) {
    const tmp = data.map((dt: any) => {
      return {
        Município: dt.municipio.nome,
        "Ponto de Distribuição": dt.ponto,
        Laticínio: dt.laticinio,
        "Gestor do Laticínio": dt.gestor_laticinio,
        "Quantidade leite bovino": dt.quantidade_leite_bovino,
        "Quantidade leite caprino": dt.quantidade_leite_caprino,
        Data: `${dt.data.split("T")[0].split("-")[2]}/${
          dt.data.split("T")[0].split("-")[1]
        }/${dt.data.split("T")[0].split("-")[0]}`,
      };
    });
    return tmp;
  }

  const createCSV = async () => {
    try {
      setDownloadingCsv(true);
      await json2csv(
        parseEntregasDocument(entregasReport),
        (err: any, csv: any) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          saveAs(csvData, "Relatorio_Entregas_Laticinio.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloadingCsv(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloadingCsv(false);
      setCsvCheck(false);
    }
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  useEffect(() => {
    const options: any = [];
    laticinios?.map((laticinio) =>
      options.push({ value: laticinio.id, label: laticinio.nome })
    );
    setLaticiniosOptions(options);
  }, [laticinios]);

  useEffect(() => {
    const options: any = [{ value: 0, label: "Todos" }];
    pontos?.map((ponto) =>
      options.push({ value: ponto.id, label: ponto.nome })
    );
    setPontosOptions(options);
  }, [pontos]);

  return (
    <>
      <Layout>
        <MainContainer
          titlePage="Relatório de Entregas do Leite por Laticínio"
          iconPage={<RiPagesLine />}
        >
          <Container>
            <div className="row">
              <div className="headerContainer">
                <div className="itemsSelectHeader">
                  <div className="inputContainer">
                    <label htmlFor="setLaticinioSelected">Laticínio</label>
                    <CustomReactSelect
                      name="setLaticinioSelected"
                      placeholder="Selecione um latícinio"
                      options={laticiniosOptions}
                      onChange={(e: any) => {
                        setLaticinioSelected(e.value);
                        setSelectedOption({ value: 0, label: "Todos" });
                        setPontoSelected(0);
                        first();
                      }}
                      noOptionsMessage={() => "Nenhum laticínio encontrado"}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                  </div>
                  <span className="space"></span>
                  <div className="inputContainer">
                    <label htmlFor="setPontoSelected">Ponto</label>
                    <CustomReactSelect
                      name="setPontoSelected"
                      value={selectedOption}
                      options={pontosOptions}
                      onChange={(e: any) => {
                        setSelectedOption({
                          value: parseInt(e.value),
                          label: e.label,
                        });
                        setPontoSelected(parseInt(e.value));
                        first();
                      }}
                      noOptionsMessage={() => "Nenhum ponto encontrado"}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                  </div>
                  <span className="space"></span>
                  <div className="inputContainer">
                    <label htmlFor="dataInicial">Data inicial</label>
                    <DateContainer>
                      <input
                        type="date"
                        className={errors.dataInicial && "error-input"}
                        name="dataInicial"
                        ref={register}
                        onChange={(e) => {
                          setDataInicial(e.currentTarget.value);
                        }}
                        placeholder="Data de início"
                      />
                      {errors.dataInicial && <span>Data inválida</span>}
                      {errors.dataInicial?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}

                      <span className="space"></span>
                    </DateContainer>
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="dataFinal">Data final</label>
                    <DateContainer>
                      <input
                        type="date"
                        className={errors.dataFinal && "error-input"}
                        name="dataFinal"
                        ref={register({
                          required: true,
                          validate: (value: any) =>
                            value === getValues("dataInicial"),
                        })}
                        onChange={(e) => {
                          setDataFinal(e.currentTarget.value);
                        }}
                        placeholder="Data de fim"
                      />
                      {errors.dataFinal && <span>Data inválida</span>}
                      {errors.dataFinal?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataFinal?.type === "validate" && (
                        <span className="error-message">
                          Inserir data futura
                        </span>
                      )}
                    </DateContainer>
                  </div>
                </div>
              </div>
            </div>
            <Table
              fields={fields}
              rows={entregas}
              hasSelection={false}
              hasSelectionAll={false}
            />
            <div className="containerFooterTable">
              Visualizando {entregas.length} de um total de {total} registros
              <div className=""></div>
              <Pagination
                itemCount={total}
                itemsPerPage={itemsPerPage}
                selectedPage={page}
                handlePageChange={changePage}
                handleNextPage={next}
                handlePreviousPage={previous}
                handleLastPage={last}
                handleFirstPage={first}
                maxPages={5}
              />
            </div>
            <div>Total de leite Bovino entregue: {totalBovino}</div>
            <div>Total de leite Caprino entregue: {totalCaprino}</div>
            <div>Total de leite entregue: {totalBovino + totalCaprino}</div>
            {entregas.length > 0 && (
              <div className="botoes" style={{ marginTop: 10 }}>
                <Button
                  onClick={() => {
                    if (!downloadingPdf) {
                      setDownloadingPdf(true);
                      getEntregasReport();
                    }
                  }}
                  name="Baixar PDF das entregas"
                  color={downloadingPdf ? "grey" : "blue"}
                  iconButtom={<MdPictureAsPdf />}
                />
                {downloadingPdf && <CustomSpinner />}
                <Button
                  onClick={() => {
                    if (!downloadingCsv) {
                      setDownloadingCsv(true);
                      setCsvCheck(true);
                      getEntregasReport();
                    }
                  }}
                  name="Baixar CSV"
                  color={downloadingCsv ? "grey" : "blue"}
                  iconButtom={<FaFileCsv />}
                />
                {downloadingCsv && <CustomSpinner />}
              </div>
            )}
          </Container>
        </MainContainer>
      </Layout>
    </>
  );
};

export default RelatorioEntregasLaticinio;
