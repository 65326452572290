import React, { useCallback, useEffect, useState } from "react";

import InputMask from "react-input-mask";

import Modal from "../../../../../template/components/Modal";
import api from "../../../../../services/api";
import { dialogBox } from "../../../../../template/utils/dialogBox";
import { useForm, Controller } from "react-hook-form";

import { CNPJMask, CPFMask } from "../../../../../template/utils/masks";
import {
  ColaboradoresPontoInterface,
  LaticinioInterface,
  MunicipioInterface,
} from "../../../../../template/utils/types";

import {
  ButtonClose,
  CustomAiOutlineClose,
  ButtonSave,
  CustomAiOutlineCheck,
} from "../../../../../template/components/Modal/styles";

import { IoIosSearch } from "react-icons/io";

import {
  Container,
  AddressContainer,
  customStyles,
  CustomSpinner,
} from "./../styles";

import { CustomInput } from "../../../../../template/styles/styles";
import colors from "../../../../../template/styles/colors";

import { Input } from "reactstrap";
import Select, { createFilter } from "react-select";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  increaseTotal: () => void;
  municipios: MunicipioInterface[];
  laticinios: LaticinioInterface[];
}

const CadastrarModal = ({
  isOpen,
  toggle,
  className,
  increaseTotal,
  municipios,
  laticinios,
}: IProps) => {
  const [colaboradores, setColaboradores] = useState<
    ColaboradoresPontoInterface[]
  >([]);
  const [cpfColaborador, setCPFColaborador] = useState<string>("");
  const {
    handleSubmit,
    register,
    errors,
    setError,
    control,
    getValues,
    formState,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [laticiniosOptions, setLaticiniosOptions] = useState<any>();
  const [entidadesParceiras, setEntidadesParceiras] = useState<any[]>([]);
  const [entidadesOptions, setEntidadesOptions] = useState<any[]>([]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  /* useEffect(() => {
    //Reseta os colaboradores
    setColaboradores([]);
  }, [toggle]); */

  function handleCPF(cpf: any) {
    clearErrors(["colaboradores"]);
    setCPFColaborador(
      cpf
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    );
  }

  async function addColaboradorHandle() {
    //Limpa o campo de matricula
    setCPFColaborador("");
    let cpfLimpo = cpfColaborador.replace(/[.-]/g, "").trim();
    //Verifica se foi digitado uma matricula
    if (cpfLimpo.length < 11)
      notify("error", "Você precisa digitar um CPF válido");
    else {
      const { data } = await api.get(`colaboradores_ponto/?cpf=${cpfLimpo}`);
      if (data.length === 0)
        notify("warning", "Nenhum colaborador encontrado!");
      else {
        let colabExistente = false;
        for (var i = 0; i < colaboradores.length; i++) {
          if (data[0].id == colaboradores[i].id) {
            notify("warning", "Colaborador já adicionado!");
            colabExistente = true;
            break;
          }
        }
        if (!colabExistente) {
          setColaboradores([...colaboradores, data[0]]);
          //Limpa o campo de matricula
          setCPFColaborador("");

          /* Indica que o form foi alterado caso algum colaborador seja removido */
          if (!formState.isDirty) {
            formState.isDirty = true;
          }
        }
      }
    }
  }

  function removeColaboradorHandle(index: number) {
    let tmpArray = [...colaboradores];
    tmpArray.splice(index, 1);
    setColaboradores(tmpArray);
  }

  function handleSetColaboradores() {
    setColaboradores([]);
    toggle();
  }

  useEffect(() => {
    const getEntidadesParceiras = async () => {
      const { data } = await api.get("entidades_cooperadas/");
      setEntidadesParceiras(data);
    };
    getEntidadesParceiras();
  }, []);

  useEffect(() => {
    const options: any = [];
    entidadesParceiras?.map((entidadeP) =>
      options.push({ value: entidadeP.id, label: entidadeP.razao_social })
    );
    setEntidadesOptions(options);
  }, [entidadesParceiras]);

  const onSubmit = async (ponto: any) => {
    //Verifica se ambos os campos de cotas estão vazios
    if (
      (getValues("cota_leite_caprino") === "0" ||
        getValues("cota_leite_caprino") === "") &&
      (getValues("cota_leite_bovino") === "0" ||
        getValues("cota_leite_bovino") === "")
    ) {
      return notify(
        "error",
        "É necessário que o Ponto possua, pelo menos, uma cota de leite!"
      );
    }
    //Cria um array com apenas os IDs dos colaboradores

    if (colaboradores.length === 0) {
      setError("colaboradores", {
        type: "manual",
      });
      notify(
        "error",
        "É necessário cadastrar ao menos um colaborador ao ponto"
      );
      return;
    }

    let tmp = [];
    for (var i = 0; i < colaboradores.length; i++) {
      tmp.push(colaboradores[i].id);
    }
    //Cria uma copia do ponto salvo
    const pontoTMP = ponto;
    pontoTMP.endereco.numero = ponto.endereco.numero.toLowerCase();
    pontoTMP.endereco.numero = ponto.endereco.numero.replace(/\D/g, "");
    //Adiciona o array de IDs dos colaboradores
    pontoTMP.colaboradores_ponto = tmp;
    //Converte inputs em string para number
    if (getValues("cota_leite_caprino") === "") {
      pontoTMP.cota_leite_caprino = 0;
    } else {
      pontoTMP.cota_leite_caprino = Number(getValues("cota_leite_caprino"));
    }

    if (getValues("cota_leite_bovino") === "") {
      pontoTMP.cota_leite_bovino = 0;
    } else {
      pontoTMP.cota_leite_bovino = Number(getValues("cota_leite_bovino"));
    }
    pontoTMP.endereco.municipio = parseInt(
      getValues("endereco.municipio").value
    );
    pontoTMP.entidade = parseInt(getValues("entidade").value);
    pontoTMP.laticinio = parseInt(getValues("laticinio").value);
    /* Remove o ".", "-" e "/" do CNPJ */
    pontoTMP.cnpj = pontoTMP.cnpj.replace(/[^\w\s]/g, "");
    pontoTMP.endereco.cep = pontoTMP.endereco.cep.replace(/[^\w\s]/g, "");

    /* Condicional pra garantir que o número será enviado como null no caso se estar vazio */
    if (pontoTMP.endereco.numero === "") {
      pontoTMP.endereco.numero = null;
    }
    console.log(ponto);
    //salva o ponto
    await api
      .post("pontos/", pontoTMP)
      .then(function (response) {
        notify("success", "Ponto cadastrado com sucesso!");
        toggle();
        increaseTotal();
        setColaboradores([]);
      })
      .catch(function (error) {
        const apiErros = error.response.data;
        if (error.response?.status === 400) {
          //Vai percorrer o objeto erros com as chaves e as mensagens de erro
          Object.entries(apiErros).map(([key, value]) => {
            //Chave endereco vem em formato de objeto por isso é necessário realizar o entries no endereco para retirada dos erros
            if (key === "endereco") {
              Object.entries(apiErros.endereco).map(([key, value]) => {
                //Input localidade usa .nome na definicação do campo por isso é necessário verificar o campo para setar o erro corretamente
                if (key === "localidade") {
                  setError("endereco." + key + ".nome", {
                    type: "manual",
                    message: "Este campo não pode ser em branco",
                  });
                } else {
                  setError("endereco." + key, {
                    type: "manual",
                    message: `${value}`,
                  });
                }
              });
            } else {
              setError(key, {
                type: "manual",
                message: `${value}`,
              });
            }
          });
          notify("error", "Erro ao cadastrar um ponto de distribuição");
        } else if (error.response?.status === 500) {
          notify("error", "Erro no servidor");
        } else if (error.response?.status === 404) {
          notify("error", "Rota não encontrada");
        } else {
          notify("error", "Ocorreu um erro na requisição a api");
        }
      });
  };

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
  }, [municipios]);

  useEffect(() => {
    const options: any = [];
    laticinios?.map((lat) => options.push({ value: lat.id, label: lat.nome }));
    setLaticiniosOptions(options);
  }, [laticinios]);

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={() => handleSetColaboradores()}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="FormCadastrarPonto"
          style={{
            backgroundColor: formState.isValid
              ? colors.greenOfficial
              : colors.mediumGrey,
          }}
          disabled={!formState.isValid}
        >
          {formState.isValid ? (
            <CustomAiOutlineCheck />
          ) : (
            <CustomAiOutlineClose />
          )}{" "}
          Cadastrar ponto
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleSetColaboradores()}
      modalTitle="Cadastrar um novo ponto de distribuição"
      footerContent={footerButtons()}
      className={className}
      fixed={true}
    >
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} id="FormCadastrarPonto">
          <div className="row title">
            <h6>Dados básicos do ponto</h6>
          </div>
          <div className="row">
            <div className="col-sm">
              {/* COLUNA DA ESQUERDA */}
              <CustomInput>
                <label htmlFor="nome">Nome do Ponto</label>
                <input
                  className={
                    errors.nome ? "CustomInput error-input" : "CustomInput"
                  }
                  placeholder="Nome completo do ponto"
                  name="nome"
                  ref={register({
                    required: true,
                  })}
                />
                {errors.nome?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.nome && (
                  <span className="error-message">{errors.nome?.message}</span>
                )}
              </CustomInput>
              <div className="space-row"></div>
              <CustomInput>
                <label htmlFor="cnpj">CNPJ</label>
                <Controller
                  className={
                    errors.cnpj ? "CustomInput error-input" : "CustomInput"
                  }
                  as={InputMask}
                  name="cnpj"
                  control={control}
                  mask={CNPJMask.mask}
                  maskChar=""
                  defaultValue=""
                  placeholder="CNPJ"
                  rules={{
                    minLength: CNPJMask.maxLength,
                  }}
                />
                {errors.cnpj?.type === "minLength" && (
                  <span className="error-message">
                    CNPJ deve conter 14 dígitos
                  </span>
                )}
                {errors.cnpj && (
                  <span className="error-message">{errors.cnpj?.message}</span>
                )}
              </CustomInput>
              <div className="space-row"></div>
              <div className="content">
                <CustomInput>
                  <label htmlFor="cota_leite_bovino">Cota Bovino</label>
                  <Controller
                    mask="9999"
                    as={InputMask}
                    maskChar=""
                    className={
                      errors.cota_leite_bovino
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="Cota bovino em litros"
                    name="cota_leite_bovino"
                    defaultValue="0"
                    control={control}
                    rules={{}}
                  />
                  {errors.cota_leite_bovino && (
                    <span className="error-message">
                      {errors.cota_leite_bovino?.message}
                    </span>
                  )}
                </CustomInput>
                <div className="space" style={{ width: 6 }}></div>
                <CustomInput>
                  <label htmlFor="cota_leite_caprino">Cota Caprino</label>
                  <Controller
                    mask="9999"
                    maskChar=""
                    as={InputMask}
                    control={control}
                    className={
                      errors.cota_leite_caprino
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="Cota caprino em litros"
                    name="cota_leite_caprino"
                    defaultValue="0"
                    rules={{}}
                  />
                  {errors.cota_leite_caprino && (
                    <span className="error-message">
                      {errors.cota_leite_caprino?.message}
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="space-row"></div>
              <div className="label">
                <div className="">
                  <label htmlFor="laticinio">Laticínio</label>
                  {laticinios.length ? "" : <CustomSpinner />}
                </div>
                <Controller
                  name="laticinio"
                  control={control}
                  placeholder={"Vincular um laticínio ao ponto"}
                  className={errors.laticinio ? "error-input" : ""}
                  as={Select}
                  rules={{ required: true }}
                  options={laticiniosOptions}
                  styles={customStyles}
                  noOptionsMessage={() => "Nenhum laticínio encontrado"}
                />

                {/* <select
                  name="laticinio"
                  className={
                    errors.laticinio
                      ? "CustomSelect error-input"
                      : "CustomSelect"
                  }
                  ref={register({
                    required: true,
                  })}
                >
                  <option value="">Vincular um laticínio ao ponto</option>
                  {laticinios.map((item) => (
                    <option value={parseInt(item.id)}>{item.nome}</option>
                  ))}
                </select> */}
                {errors.laticinio?.type === "required" && (
                  <span className="error-message">Selecione um laticínio</span>
                )}
                {errors.laticinio && (
                  <span className="error-message">
                    {errors.laticinio?.message}
                  </span>
                )}
              </div>
              <div className="space-row"></div>
              <label htmlFor="entidade">Entidade</label>
              <Controller
                name="entidade"
                placeholder={"Selecione uma entidade"}
                rules={{ required: true }}
                control={control}
                className={errors.entidade ? "error-input" : ""}
                as={Select}
                menuPlacement="bottom"
                options={entidadesOptions}
                noOptionsMessage={() => "Nenhuma entidade encontrada"}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              {errors.entidade && (
                <span className="error-message">
                  É necessário selecionar uma entidade
                </span>
              )}
            </div>
            <div className="col-sm">
              {/* COLUNA DA DIREITA */}
              <label htmlFor="cpf">CPF do Colaborador</label>
              <div className="content">
                <CustomInput>
                  <Input
                    className={
                      errors.colaboradores
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="CPF do colaborador"
                    maxLength={14}
                    value={cpfColaborador}
                    onChange={(e) => {
                      handleCPF(e.currentTarget.value);
                    }}
                  />
                </CustomInput>
                <span className="space"></span>
                <a
                  href="#AdicionarColaborador"
                  className="addColaboradorButton"
                  onClick={() => addColaboradorHandle()}
                >
                  <IoIosSearch style={{ width: 18, height: 18 }} /> Adicionar
                </a>
              </div>
              <div className="space-row"></div>
              <h6>Lista de colaboradores</h6>
              <hr />
              <ul className="list">
                {colaboradores.length === 0 ? (
                  <li>Lista vazia</li>
                ) : (
                  colaboradores.map((item) => (
                    <li className="listItem">
                      {item.pessoa.nome}
                      {"  -"}
                      <a
                        href="#RemoveColaborador"
                        onClick={() =>
                          removeColaboradorHandle(colaboradores.indexOf(item))
                        }
                        className="removeItem"
                      >
                        Remover
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          {/* LINHA COM OS INPUTS DE ENDEREÇO */}
          <div className="space-row"></div>
          <div className="row title">
            <h6>Endereço</h6>
          </div>

          <AddressContainer>
            <div className="label">
              <label htmlFor="endereco.cep">CEP</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                defaultValue=""
                maskChar=""
                placeholder="99999-999"
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">CEP deve conter 8 dígitos</span>
              )}
              {errors.endereco?.cep && (
                <span className="error-message">
                  {errors.endereco?.cep?.message}
                </span>
              )}
            </div>
            <div className="space"></div>

            <div className="label">
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Av. Sen. Salgado Filho"
                name="endereco.rua"
                style={{ width: 320 }}
                ref={register({
                  required: true,
                })}
              />
              {errors.endereco?.rua?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.rua && (
                <span className="error-message">
                  {errors.endereco?.rua?.message}
                </span>
              )}
            </div>

            <div className="space"></div>

            <div className="label">
              <label htmlFor="endereco.numero">Número</label>
              <Controller
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.numero"
                defaultValue="s/n"
                control={control}
                mask=""
                maskChar=""
                style={{ width: 120 }}
                rules={{
                  required: false,
                }}
              />
              {errors.endereco?.numero && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <div className="label">
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Tirol"
                name="endereco.bairro"
                style={{ width: 220 }}
                ref={register({
                  required: true,
                })}
              />
              {errors.endereco?.bairro?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.bairro && (
                <span className="error-message">
                  {errors.endereco?.bairro?.message}
                </span>
              )}
            </div>

            <div className="space"></div>

            <div className="label">
              <label htmlFor="endereco.localidade.nome">Localidade</label>
              <input
                className={
                  errors.endereco?.localidade
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Ao lado do Midway Mall"
                name="endereco.localidade.nome"
                style={{ width: 220 }}
                ref={register({
                  required: true,
                })}
              />
              {errors.endereco?.localidade?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.localidade?.nome && (
                <span className="error-message">
                  {errors.endereco?.localidade?.nome?.message}
                </span>
              )}
            </div>

            <div className="space"></div>

            <div className="label">
              <label htmlFor="endereco.municipio">Município</label>
              <Controller
                className={errors.endereco?.municipio ? "error-input" : ""}
                name="endereco.municipio"
                placeholder={"Selecione um município"}
                rules={{ required: true }}
                control={control}
                as={Select}
                menuPlacement="top"
                options={municipiosOptions}
                styles={customStyles}
                filterOption={createFilter({ ignoreAccents: false })}
                noOptionsMessage={() => "Nenhum município encontrado"}
              />

              {/* <select
                name="endereco.municipio"
                className={
                  errors.endereco?.municipio
                    ? "CustomSelect error-input"
                    : "CustomSelect"
                }
                ref={register({
                  required: true,
                })}
              >
                <option value="">Selecione um município</option>
                {municipios.map((item) => (
                  <option value={parseInt(item.id)}>{item.nome}</option>
                ))}
              </select> */}
              {errors.endereco?.municipio?.type === "required" && (
                <span className="error-message">Selecione um município</span>
              )}
              {errors.endereco?.municipio && (
                <span className="error-message">
                  {errors.endereco?.municipio?.message}
                </span>
              )}
            </div>
          </AddressContainer>
        </form>
      </Container>
    </Modal>
  );
};

export default CadastrarModal;
