type tokenType = {
  is_gestor_estadual: boolean;
  is_gestor_local: boolean;
  is_gestor_laticinio: boolean;
  is_colaborador_ponto: boolean;
};

export const isAuthenticated = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    const { exp, is_gestor_local } = parseJwt(refreshToken);
    if (Date.now() >= exp * 1000 || is_gestor_local === false) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getToken = () => localStorage.getItem("accessToken");

export const login = (tokens: { access: string; refresh: string }) => {
  localStorage.setItem("accessToken", tokens.access);
  localStorage.setItem("refreshToken", tokens.refresh);
};

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getUserToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    const token: tokenType = parseJwt(refreshToken);
    return token;
  }
  return {
    is_gestor_estadual: false,
    is_gestor_local: false,
    is_gestor_laticinio: false,
    is_colaborador_ponto: false,
  };
};

export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
