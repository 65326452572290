import React from "react";

import { HomeContent } from "./styles";
import CardsComponent from "./components/CardsComponent";

import Layout from "../../template/components/Layout";

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <HomeContent>
        <CardsComponent />
      </HomeContent>
    </Layout>
  );
};

export default Dashboard;
