import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

import brasaoRN from "../../../../../template/assets/logo/logo_estado.png";
import { parseData } from "../../../../../template/utils/parser";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    marginTop: 5,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

type DataTableValue = {
  [k: string]: string;
};
interface IProps {
  data: DataTableValue[];
  totalBovino: number;
  totalCaprino: number;
  count: number;
  municipio: string;
  ponto: string;
  selectedPonto: number;
  startPage: number;
  endPage: number;
  agregado: boolean;
  tipoDoacao: number;
}

const DocumentPDF = ({
  data,
  municipio,
  ponto,
  count,
  selectedPonto,
  totalBovino,
  totalCaprino,
  startPage,
  endPage,
  agregado,
  tipoDoacao,
}: IProps) => {
  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${AddLeadingZeros(temp.getUTCDate())}/${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}/${temp.getUTCFullYear()}`;
    return formated;
  };

  return (
    <>
      <Document title="Lista de famílias em espera">
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.image} source={brasaoRN} />
            <View style={styles.headerTextSection}>
              <Text style={styles.headerText}>
                GOVERNO DO ESTADO DO RIO GRANDE DO NORTE
              </Text>
              <Text style={styles.headerText}>
                SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
                SOCIAL - SETHAS
              </Text>
              <Text style={styles.headerText}>PROGRAMA DO LEITE POTIGUAR</Text>
            </View>
            <View style={styles.titleTextSection}>
              {agregado ? (
                <Text style={styles.titleText}>
                  RELATÓRIO DE DOAÇÕES DO LEITE AGRUPADAS POR{" "}
                  {tipoDoacao === 1
                    ? "PESSOAS FÍSICAS"
                    : "ENTIDADES BENEFICIÁRIAS"}
                </Text>
              ) : (
                <Text style={styles.titleText}>
                  RELATÓRIO DE DOAÇÕES DO LEITE
                </Text>
              )}
              {selectedPonto > 0 && !agregado ? (
                <Text style={styles.titleText}>
                  Doações vinculadas ao ponto {ponto} do município de{" "}
                  {municipio} referentes à paginação {startPage} até {endPage}
                </Text>
              ) : (
                <Text style={styles.titleText}>
                  Doações vinculadas aos pontos do município de {municipio}{" "}
                  referentes à paginação {startPage} até {endPage}
                </Text>
              )}
            </View>
          </View>
          <Table data={[...data]}>
            <TableHeader>
              {agregado && tipoDoacao == 1 && (
                <TableCell style={styles.cell}>{"Nome"}</TableCell>
              )}
              <TableCell style={styles.cell}>
                {agregado
                  ? tipoDoacao == 1
                    ? "CPF"
                    : "Entidade Beneficiaria"
                  : "Nome"}
              </TableCell>
              <TableCell style={styles.cell}>
                {agregado ? "Quantidade Doacoes" : "CPF/CNPJ"}
              </TableCell>
              {!agregado && <TableCell style={styles.cell}>Telefone</TableCell>}
              <TableCell style={styles.cell}>Qtd bovino</TableCell>
              <TableCell style={styles.cell}>Qtd caprino</TableCell>
              {!agregado && <TableCell style={styles.cell}>Ponto</TableCell>}
            </TableHeader>
            <TableBody>
              {agregado && tipoDoacao == 1 && (
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => {
                    return r.nome;
                  }}
                />
              )}
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => {
                  if (agregado) {
                    if (tipoDoacao == 1) return r.cpf;
                    else return r.entidade_beneficiaria;
                  } else return r.nome;
                }}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => {
                  if (agregado) return r.quantidade_doacoes;
                  else return r.documento;
                }}
              />
              {!agregado && (
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => r.telefone}
                />
              )}
              {
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => r.quantidade_leite_bovino}
                />
              }
              {
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => r.quantidade_leite_caprino}
                />
              }
              {!agregado && (
                <DataTableCell
                  style={styles.cellData}
                  getContent={(r) => r.ponto}
                />
              )}
            </TableBody>
          </Table>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <Table data={[3]}>
            <TableHeader>
              <TableCell style={styles.cell}>Total de doações</TableCell>
              <TableCell style={styles.cell}>
                Total de leite Bovino doado
              </TableCell>
              <TableCell style={styles.cell}>
                Total de leite Caprino doado
              </TableCell>
              <TableCell style={styles.cell}>Total de leite doado</TableCell>
            </TableHeader>
            <TableBody>
              <TableCell style={styles.cell}>{count}</TableCell>
              <TableCell style={styles.cell}>{totalBovino}</TableCell>
              <TableCell style={styles.cell}>{totalCaprino}</TableCell>
              <TableCell style={styles.cell}>
                {totalCaprino + totalBovino}
              </TableCell>
            </TableBody>
          </Table>
          <View style={styles.MealsCountSection}>
            <Text style={styles.MealsCountTextValue}></Text>
          </View>
          <View style={styles.currentDate}>
            <Text style={styles.MealsCountText}>
              Documento gerado em {parseData(new Date().toUTCString())}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default DocumentPDF;
