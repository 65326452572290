import React from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  CustomFaBars,
  CustomRiLogoutBoxLine,
  LogoutButton,
} from "./styles";

import { logout } from "../../../services/auth";

import logoEstado from "../../assets/icons/logo_estado.svg";

interface IProps {
  toggle: () => void;
}

const Header = ({ toggle }: IProps) => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push("/");
  };

  return (
    <Container>
      <div>
        <img src={logoEstado} alt="Logo do estado do RN" />
        <CustomFaBars onClick={toggle} />
        <a href="http://www.sethas.rn.gov.br/">Portal SETHAS</a>
        {/* <a href="#programas">Programas</a> */}
        <a href="https://sei.rn.gov.br/">SEI</a>
      </div>
      <div>
        {/* <CustomBsBell /> */}
        {/* <ProfileImageFrame>foto</ProfileImageFrame> */}
        <LogoutButton onClick={handleLogout}>
          Sair <CustomRiLogoutBoxLine className="inside" />
        </LogoutButton>
      </div>
    </Container>
  );
};

export default Header;
