import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import { Container } from "../../styles";
import { FormContainer } from "./style";

interface IProps {
  row: any;
  toggle: () => void;
  alterarSituacao: (row: any, data: any) => void;
}

const ConfirmarForm: React.FC<IProps> = (props) => {
  const { register, handleSubmit } = useForm<any>();
  const [situacaoConfirmada, setSituacaoConfirmada] = useState<number>(0);
  const [situacaoInapta, setSituacaoInapta] = useState<number>(0);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    const getSituacao = async () => {
      const { data } = await api.get("situacoes/");
      let situacaoConfirmada = data.find(
        (element: { situacao: string }) => element.situacao === "CONFIRMADA"
      );
      let situacaoInapta = data.find(
        (element: { situacao: string }) => element.situacao === "INAPTO"
      );
      setSituacaoConfirmada(situacaoConfirmada.id);
      setSituacaoInapta(situacaoInapta.id);
    };
    getSituacao();
  }, []);

  const dataConfirmar = { situacao: situacaoConfirmada };

  const onSubmit = () => {
    props.alterarSituacao(props.row, dataConfirmar);
    props.toggle();
  };

  return (
    <Container>
      <FormContainer id="confirmarForm" onSubmit={handleSubmit(onSubmit)}>
        <h6>Deseja confirmar essa família?</h6>
      </FormContainer>
    </Container>
  );
};

export default ConfirmarForm;
