import React, { useCallback, useEffect, useState } from "react";

import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";

import Modal from "../../../../../template/components/Modal";
import api from "../../../../../services/api";
import { dialogBox } from "../../../../../template/utils/dialogBox";

import {
  PontoInterface,
  ColaboradoresPontoInterface,
  MunicipioInterface,
  LaticinioInterface,
} from "../../../../../template/utils/types";

import {
  ButtonClose,
  CustomAiOutlineClose,
  ButtonSave,
  CustomAiOutlineCheck,
} from "../../../../../template/components/Modal/styles";

import { IoIosSearch } from "react-icons/io";

import {
  Container,
  AddressContainer,
  customStyles,
  CustomSpinner,
} from "./../styles";

import { CustomInput } from "../../../../../template/styles/styles";
import colors from "../../../../../template/styles/colors";
import { Input } from "reactstrap";
import Select, { createFilter } from "react-select";
import { CNPJMask } from "../../../../../template/utils/masks";
import { parseCNPJ } from "../../../../../template/utils/parser";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  municipioSelecionado: number;
  pontoEditar: any;
  municipios: MunicipioInterface[];
  laticinios: LaticinioInterface[];
}

const EditarModal = ({
  isOpen,
  toggle,
  className,
  municipioSelecionado,
  pontoEditar,
  municipios,
  laticinios,
}: IProps) => {
  const [colaboradores, setColaboradores] = useState<
    ColaboradoresPontoInterface[]
  >(pontoEditar.colaboradores_ponto);
  const [cpfColaborador, setCPFColaborador] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    errors,
    setError,
    control,
    getValues,
    formState,
    setValue,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const ponto: PontoInterface = {
    /* A string "...." deve ser concatenada com o cnpj para o formulario indentificar o CNPJ como uma string de 18 caracteres no primeiro render */
    /* A mascara do input de CNPJ vai eliminar a string "....", a mesma é apenas para não da erro na primeira validação do formulário */
    cnpj: pontoEditar.cnpj !== "" ? parseCNPJ(pontoEditar.cnpj) : "",
    colaboradores_ponto: pontoEditar.colaboradores_ponto,
    cota_leite_bovino: pontoEditar.cota_leite_bovino,
    cota_leite_caprino: pontoEditar.cota_leite_caprino,
    endereco: {
      bairro: pontoEditar.endereco.bairro,
      cep: pontoEditar.endereco.cep + "..",
      localidade: pontoEditar.endereco.localidade,
      municipio: pontoEditar.endereco.municipio.id,
      numero: pontoEditar.endereco.numero,
      rua: pontoEditar.endereco.rua,
    },
    entidade: pontoEditar.entidade.id,
    id: pontoEditar.id,
    laticinio: pontoEditar.laticinio.id,
    nome: pontoEditar.nome,
  };
  const [municipiosOptions, setMunicipiosOptions] = useState<any>([]);
  const [laticiniosOptions, setLaticiniosOptions] = useState<any>([]);
  const [entidadesParceiras, setEntidadesParceiras] = useState<any[]>([]);
  const [entidadesOptions, setEntidadesOptions] = useState<any[]>([]);
  const [selectedOptionMunicipio, setSelectedOptionMunicipio] = useState<any>();
  const [selectedOptionLaticinio, setSelectedOptionLaticinio] = useState<any>();
  const [entidadeSelected, setEntidadeSelected] = useState<any>();

  useEffect(() => {
    /*Ao entrar no useEffect o botão é sempre setado em false (ativado)*/
    setButtonDisable(false);

    if (!formState.isDirty) {
      setButtonDisable(true);
    }

    /* O for vai verificar se o objeto formState não está vazio, se possuir qualquer chave é porque não está vazio
    e com isso o botao será desativado */
    if (Object.keys(formState.errors).length > 0) {
      setButtonDisable(true);
    }
  }, [formState]);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  function handleCPF(cpf: any) {
    clearErrors(["colaboradores"]);
    setCPFColaborador(
      cpf
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    );
  }

  async function addColaboradorHandle() {
    //Limpa o campo de matricula
    setCPFColaborador("");
    let cpfLimpo = cpfColaborador.replace(/[.-]/g, "").trim();
    //Verifica se foi digitado uma matricula
    if (cpfLimpo.length < 11)
      notify("error", "Você precisa digitar um CPF válido");
    else {
      const { data } = await api.get(`colaboradores_ponto/?cpf=${cpfLimpo}`);
      if (data.length === 0)
        notify("warning", "Nenhum colaborador encontrado!");
      else {
        let colabExistente = false;
        for (var i = 0; i < colaboradores.length; i++) {
          if (data[0].id == colaboradores[i].id) {
            notify("warning", "Colaborador já adicionado!");
            colabExistente = true;
            break;
          }
        }
        if (!colabExistente) {
          setColaboradores([...colaboradores, data[0]]);
          formState.isDirty = true;
          //Limpa o campo de matricula
          setCPFColaborador("");
        }
      }
    }
  }

  function removeColaboradorHandle(index: number) {
    let tmpArray = [...colaboradores];
    tmpArray.splice(index, 1);
    setColaboradores(tmpArray);

    /* Indica que o form foi alterado caso algum colaborador seja removido */
    if (!formState.isDirty) {
      formState.isDirty = true;
    }
  }

  useEffect(() => {
    const getEntidadesParceiras = async () => {
      const { data } = await api.get("entidades_cooperadas/");
      setEntidadesParceiras(data);
    };
    getEntidadesParceiras();
  }, []);

  useEffect(() => {
    const options: any = [];
    entidadesParceiras?.map((entidadeP) =>
      options.push({ value: entidadeP.id, label: entidadeP.razao_social })
    );
    setEntidadesOptions(options);
    //console.log(ponto)
    setEntidadeSelected(
      options.find((item: any) => ponto.entidade === item.value)
    );
    console.log(ponto);
  }, [entidadesParceiras]);

  const onSubmit = async (ponto: any) => {
    //Verifica se ambos os campos de cotas estão vazios
    if (
      (getValues("cota_leite_caprino") === "0" ||
        getValues("cota_leite_caprino") === "" ||
        getValues("cota_leite_caprino") === 0) &&
      (getValues("cota_leite_bovino") === "0" ||
        getValues("cota_leite_bovino") === "" ||
        getValues("cota_leite_bovino") === 0)
    ) {
      return notify(
        "error",
        "É necessário que o Ponto possua, pelo menos, uma cota de leite!"
      );
    }
    //Cria um array com apenas os IDs dos colaboradores

    if (colaboradores.length === 0) {
      setError("colaboradores", {
        type: "manual",
      });
      notify(
        "error",
        "É necessário cadastrar ao menos um colaborador ao ponto"
      );
      return;
    }

    let tmp = [];
    for (var i = 0; i < colaboradores.length; i++) {
      tmp.push(colaboradores[i].id);
    }

    // Resolve bug de valor undefined quando não modificado o select com um novo municipio ou laticinio
    if (
      getValues("endereco.municipio") === undefined ||
      getValues("laticinio") === undefined ||
      getValues("entidade") === undefined
    ) {
      setValue("endereco.municipio", selectedOptionMunicipio);
      setValue("laticinio", selectedOptionLaticinio);
      setValue("entidade", entidadeSelected);
    }

    //Cria uma copia do ponto salvo
    const pontoTMP = ponto;
    pontoTMP.endereco.numero = ponto.endereco.numero.toLowerCase();
    pontoTMP.endereco.numero = ponto.endereco.numero.replace(/\D/g, "");
    //Adiciona o array de IDs dos colaboradores
    pontoTMP.colaboradores_ponto = tmp;
    //Converte inputs em string para number
    if (getValues("cota_leite_caprino") === "") {
      pontoTMP.cota_leite_caprino = 0;
    } else {
      pontoTMP.cota_leite_caprino = Number(getValues("cota_leite_caprino"));
    }

    if (getValues("cota_leite_bovino") === "") {
      pontoTMP.cota_leite_bovino = 0;
    } else {
      pontoTMP.cota_leite_bovino = Number(getValues("cota_leite_bovino"));
    }
    pontoTMP.endereco.municipio = getValues("endereco.municipio").value;
    pontoTMP.entidade = getValues("entidade").value;
    pontoTMP.laticinio = getValues("laticinio").value;
    /* Remove o ".", "-" e "/" do CNPJ e do CEP*/
    pontoTMP.cnpj = pontoTMP.cnpj.replace(/[^\w\s]/g, "");
    pontoTMP.endereco.cep = pontoTMP.endereco.cep.replace(/[^\w\s]/g, "");
    /* Condicional pra garantir que o número será enviado como null no caso se estar vazio */
    if (pontoTMP.endereco.numero === "") {
      pontoTMP.endereco.numero = null;
    }
    //salva o ponto
    await api
      .put(`pontos/${pontoEditar.id}/`, pontoTMP)
      .then(function () {
        notify("success", "Ponto editado com sucesso!");
        toggle();
      })
      .catch(function (error) {
        const apiErros = error.response.data;
        if (error.response?.status === 400) {
          //Vai percorrer o objeto erros com as chaves e as mensagens de erro
          Object.entries(apiErros).map(([key, value]) => {
            //Chave endereco vem em formato de objeto por isso é necessário realizar o entries no endereco para retirada dos erros
            if (key === "endereco") {
              Object.entries(apiErros.endereco).map(([key, value]) => {
                //Input localidade usa .nome na definicação do campo por isso é necessário verificar o campo para setar o erro corretamente
                if (key === "localidade") {
                  setError("endereco." + key + ".nome", {
                    type: "manual",
                    message: "Este campo não pode ser em branco",
                  });
                } else {
                  setError("endereco." + key, {
                    type: "manual",
                    message: `${value}`,
                  });
                }
              });
            } else {
              setError(key, {
                type: "manual",
                message: `${value}`,
              });
            }
          });
          notify("error", "Erro ao editar um ponto de distribuição");
        } else if (error.response?.status === 500) {
          notify("error", "Erro no servidor");
        } else if (error.response?.status === 404) {
          notify("error", "Rota não encontrada");
        } else {
          notify("error", "Ocorreu um erro na requisição a api");
        }
      });
  };

  useEffect(() => {
    const options: any = [];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipiosOptions(options);
    setSelectedOptionMunicipio(
      options.find((item: any) => ponto.endereco.municipio === item.value)
    );
  }, [municipios]);

  useEffect(() => {
    const options: any = [];
    laticinios?.map((lat) => options.push({ value: lat.id, label: lat.nome }));
    setLaticiniosOptions(options);
    setSelectedOptionLaticinio(
      options.find((item: any) => ponto.laticinio === item.value)
    );
  }, [laticinios]);

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="FormEditarPonto"
          style={{
            backgroundColor: buttonDisable
              ? colors.mediumGrey
              : colors.greenOfficial,
          }}
          disabled={buttonDisable}
        >
          {buttonDisable ? <CustomAiOutlineClose /> : <CustomAiOutlineCheck />}{" "}
          Editar ponto
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      modalTitle="Editar ponto de distribuição"
      footerContent={footerButtons()}
      className={className}
      fixed={true}
    >
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} id="FormEditarPonto">
          <div className="row title">
            <h6>Dados básicos do ponto</h6>
          </div>
          <div className="row">
            <div className="col-sm">
              {/* COLUNA DA ESQUERDA */}
              <CustomInput>
                <label htmlFor="nome">Nome do Ponto</label>
                <input
                  className={
                    errors.nome ? "CustomInput error-input" : "CustomInput"
                  }
                  placeholder="Nome completo do ponto"
                  name="nome"
                  ref={register({ required: true })}
                  defaultValue={ponto.nome}
                />
                {errors.nome?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.nome && (
                  <span className="error-message">{errors.nome?.message}</span>
                )}
              </CustomInput>
              <div className="space-row"></div>
              <CustomInput>
                <label htmlFor="cnpj">CNPJ</label>
                <Controller
                  className={
                    errors.cnpj ? "CustomInput error-input" : "CustomInput"
                  }
                  as={InputMask}
                  name="cnpj"
                  control={control}
                  mask={CNPJMask.mask}
                  maskChar=""
                  placeholder="CNPJ do ponto"
                  defaultValue={ponto.cnpj}
                  rules={{
                    required: false,
                    minLength: CNPJMask.maxLength,
                  }}
                />
                {errors.cnpj?.type === "minLength" && (
                  <span className="error-message">
                    CNPJ deve conter 14 dígitos
                  </span>
                )}
                {errors.cnpj && (
                  <span className="error-message">{errors.cnpj?.message}</span>
                )}
              </CustomInput>
              <div className="space-row"></div>
              <div className="content">
                <CustomInput>
                  <label htmlFor="cota_leite_bovino">Cota Bovino</label>
                  <Controller
                    mask="9999"
                    maskChar=""
                    as={InputMask}
                    className={
                      errors.cota_leite_bovino
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="Cota bovino em litros"
                    name="cota_leite_bovino"
                    control={control}
                    defaultValue={ponto.cota_leite_bovino}
                    rules={{}}
                  />
                  {errors.cota_leite_bovino && (
                    <span className="error-message">
                      {errors.cota_leite_bovino?.message}
                    </span>
                  )}
                </CustomInput>
                <div className="space" style={{ width: 6 }}></div>
                <CustomInput>
                  <label htmlFor="cota_leite_caprino">Cota Caprino</label>
                  <Controller
                    mask="9999"
                    maskChar=""
                    as={InputMask}
                    control={control}
                    defaultValue={ponto.cota_leite_caprino}
                    className={
                      errors.cota_leite_caprino
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="Cota caprino em litros"
                    name="cota_leite_caprino"
                    rules={{}}
                  />
                  {errors.cota_leite_caprino && (
                    <span className="error-message">
                      {errors.cota_leite_caprino?.message}
                    </span>
                  )}
                </CustomInput>
              </div>
              <div className="space-row"></div>
              <div className="label">
                <div className="">
                  <label htmlFor="laticinio">Laticínio</label>
                  {laticinios.length ? "" : <CustomSpinner />}
                </div>
                <Controller
                  name="laticinio"
                  control={control}
                  rules={{ required: laticinios.length === 0 ? true : false }}
                  render={({ onChange }) => (
                    <Select
                      styles={customStyles}
                      className={errors.laticinio ? "error-input" : ""}
                      defaultValue={{
                        value: 0,
                        label: "Vincular um laticínio ao ponto",
                      }}
                      options={laticiniosOptions}
                      value={selectedOptionLaticinio}
                      onChange={(val) => {
                        setSelectedOptionLaticinio(val);
                        onChange(val);
                      }}
                      noOptionsMessage={() => "Nenhum laticínio encontrado"}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                  )}
                />
                {/* <select
                  name="laticinio"
                  className={
                    errors.laticinio
                      ? "CustomSelect error-input"
                      : "CustomSelect"
                  }
                  ref={register({ required: true })}
                >
                  {laticinios.map((item) =>
                    ponto.laticinio === item.id ? (
                      <option value={parseInt(item.id)} selected>
                        {item.nome}
                      </option>
                    ) : (
                      <option value={parseInt(item.id)}>{item.nome}</option>
                    )
                  )}
                </select> */}
                {errors.laticinio && (
                  <span className="error-message">Selecione um laticínio</span>
                )}
              </div>
              <div className="space-row"></div>
              <label htmlFor="entidade">Entidade</label>
              <Controller
                name="entidade"
                control={control}
                render={({ onChange }) => (
                  <Select
                    styles={customStyles}
                    menuPlacement="bottom"
                    options={entidadesOptions}
                    value={entidadeSelected}
                    placeholder={"Selecione uma entidade"}
                    onChange={(val) => {
                      setEntidadeSelected(val);
                      onChange(val);
                    }}
                    noOptionsMessage={() => "Nenhuma entidade encontrada"}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                )}
              />
              {errors.endereco?.municipio?.id && (
                <span className="error-message">Selecione um município</span>
              )}
            </div>
            <div className="col-sm">
              {/* COLUNA DA DIREITA */}
              <label htmlFor="cpf">CPF do Colaborador</label>
              <div className="content">
                <CustomInput>
                  <Input
                    className={
                      errors.colaboradores
                        ? "CustomInput error-input"
                        : "CustomInput"
                    }
                    placeholder="CPF do colaborador"
                    maxLength={14}
                    value={cpfColaborador}
                    onChange={(e) => {
                      handleCPF(e.currentTarget.value);
                    }}
                  />
                </CustomInput>
                <span className="space"></span>
                <a
                  href="#AdicionarColaborador"
                  className="addColaboradorButton"
                  onClick={() => addColaboradorHandle()}
                >
                  <IoIosSearch style={{ width: 18, height: 18 }} /> Adicionar
                </a>
              </div>
              <div className="space-row"></div>
              <h6>Lista de colaboradores</h6>
              <hr />
              <ul className="list">
                {colaboradores.length === 0 ? (
                  <li>Lista vazia</li>
                ) : (
                  colaboradores.map((item) => (
                    <li className="listItem">
                      {item.pessoa.nome}
                      {"  -"}
                      <a
                        href="#RemoveColaborador"
                        onClick={() =>
                          removeColaboradorHandle(colaboradores.indexOf(item))
                        }
                        className="removeItem"
                      >
                        Remover
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          {/* LINHA COM OS INPUTS DE ENDEREÇO */}
          <div className="space-row"></div>
          <div className="row title">
            <h6>Endereço</h6>
          </div>
          <AddressContainer>
            <div className="label">
              <label htmlFor="endereco.cep">CEP</label>
              <Controller
                className={
                  errors.endereco?.cep
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.cep"
                control={control}
                mask="99999-999"
                defaultValue={ponto.endereco.cep}
                maskChar=""
                placeholder="ex: 59015-000"
                rules={{
                  required: true,
                  minLength: 9,
                }}
              />
              {errors.endereco?.cep?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.endereco?.cep?.type === "minLength" && (
                <span className="error-message">CEP deve conter 8 dígitos</span>
              )}
              {errors.endereco?.cep && (
                <span className="error-message">
                  {errors.endereco?.cep?.message}
                </span>
              )}
            </div>

            <span className="space"></span>

            <div className="label">
              <label htmlFor="endereco.rua">Logradouro</label>
              <input
                className={
                  errors.endereco?.rua
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Av. Sen. Salgado Filho"
                defaultValue={ponto.endereco.rua}
                name="endereco.rua"
                style={{ width: 320 }}
                ref={register({ required: true })}
              />
              {errors.endereco?.rua && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <span className="space"></span>

            <div className="label">
              <label htmlFor="endereco.numero">Número</label>
              <Controller
                className={
                  errors.endereco?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                as={InputMask}
                name="endereco.numero"
                defaultValue={
                  ponto.endereco.numero === null ? "s/n" : ponto.endereco.numero
                }
                control={control}
                mask=""
                maskChar=""
                style={{ width: 120 }}
                /* rules={{
        required: true,
      }} */
              />
              {errors.endereco?.numero && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <div className="label">
              <label htmlFor="endereco.bairro">Bairro</label>
              <input
                className={
                  errors.endereco?.bairro
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Tirol"
                name="endereco.bairro"
                style={{ width: 220 }}
                defaultValue={ponto.endereco.bairro}
                ref={register({ required: true })}
              />
              {errors.endereco?.bairro && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <span className="space"></span>

            <div className="label">
              <label htmlFor="endereco.localidade.nome">Localidade</label>
              <input
                className={
                  errors.endereco?.localidade?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="ex: Ao lado do Midway Mall"
                defaultValue={ponto.endereco.localidade.nome}
                name="endereco.localidade.nome"
                style={{ width: 220 }}
                ref={register({ required: true })}
              />
              {errors.endereco?.localidade?.nome && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <span className="space"></span>

            <div className="label">
              <label htmlFor="endereco.municipio">Município</label>
              <Controller
                name="endereco.municipio"
                control={control}
                render={({ onChange }) => (
                  <Select
                    styles={customStyles}
                    menuPlacement="top"
                    options={municipiosOptions}
                    value={selectedOptionMunicipio}
                    onChange={(val) => {
                      setSelectedOptionMunicipio(val);
                      onChange(val);
                    }}
                    noOptionsMessage={() => "Nenhum município encontrado"}
                    // Melhora o desempenho ao passar o mouse pelas opções
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                )}
              />
              {/* <select
                name="endereco.municipio"
                className={
                  errors.endereco?.municipio
                    ? "CustomSelect error-input"
                    : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                <option value="">Selecione um município</option>
                {municipios.map((item) =>
                  ponto.endereco.municipio === item.id ? (
                    <option value={parseInt(item.id)} selected>
                      {item.nome}
                    </option>
                  ) : (
                    <option value={parseInt(item.id)}>{item.nome}</option>
                  )
                )}
              </select> */}
              {errors.endereco?.municipio && (
                <span className="error-message">Selecione um município</span>
              )}
            </div>
          </AddressContainer>
        </form>
      </Container>
    </Modal>
  );
};

export default EditarModal;
