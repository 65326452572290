import styled from "styled-components";
import Select from "react-select";
import colors from "../../template/styles/colors";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { Spinner } from "reactstrap";

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
  }
  .space {
    margin-left: 0px;
  }
  table {
    margin-top: 10px;
  }
  h6 {
    font-weight: bolder;
    margin: 0;
  }
  .bottom-buttons {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 12px 0;
    align-items: center;
  }
  label {
    margin: 0;
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }
  .select {
    width: auto;
  }
  .searchBar {
    margin-top: 10px;
    margin-right: 5px;
  }
`;

export const CustomSpinner = styled(Spinner)`
  color: gray;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 2px;
`;

export const CustomReactSelect = styled(Select)`
  width: 200px;
`;

export const CustomIconConfirmar = styled(AiOutlineCheckCircle)`
  font-size: 1.5rem;
  color: green;
`;

export const CustomIconDesvincular = styled(TiDeleteOutline)`
  font-size: 1.5rem;
  color: red;
`;
