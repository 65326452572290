import React from "react";
import Modal from "../../../../template/components/Modal";
import "./styles.ts";
import PdfForm from "../PdfForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import { createGlobalStyle } from "styled-components";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  getFamilias: (start: number, end: number) => void;
  endPageDefault: number;
}

interface IStyle {
  modalOpen: boolean;
}

// Retira a barra de rolagem do body após fechar o modal, mas mantém a barra com o modal aberto
const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

const PdfModal = ({
  isOpen,
  toggle,
  className,
  getFamilias,
  endPageDefault,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose
          onClick={() => {
            toggle();
          }}
        >
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="pdfForm">
          <CustomAiOutlineCheck />
          {"Confirmar geração de PDF"}
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={false}
      modalTitle={
        "Selecione o intervalo da paginação para gerar o pdf da lista (Obs: Intervalo máximo 50)"
      }
      footerContent={footerButtons()}
      className={className}
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <div className="mt-2">
        <PdfForm
          endPageDefault={endPageDefault}
          getFamilias={(startPage: number, endPage: number) =>
            getFamilias(startPage, endPage)
          }
        />
      </div>
    </Modal>
  );
};

export default PdfModal;
