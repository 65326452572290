import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .space {
    margin-left: 20px;
  }
  .searchBar {
    margin-right: 9px;
  }
  .inputContainer {
    display: flex;
    flex-direction: row;
  }
  .containerFooterTable {
    display: flex;
    justify-content: space-between;
  }
`;

export const CustomReactSelect = styled(Select)`
  min-width: 200px;
`;
