import React from "react";
import Modal from "../../../../../template/components/Modal";
import "./styles.ts";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../DetalharModal/styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  tipo: any;
  desvincular: () => void;
  trocar: () => void;
}

const AlertModal = ({
  tipo,
  isOpen,
  toggle,
  className,
  desvincular,
  trocar,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        {tipo?.acao === "desvincular" ? (
          <ButtonSave onClick={desvincular}>
            <CustomAiOutlineCheck /> Confirmar
          </ButtonSave>
        ) : (
          <ButtonSave onClick={trocar}>
            <CustomAiOutlineCheck /> Confirmar
          </ButtonSave>
        )}
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={false}
      modalTitle={""}
      footerContent={footerButtons()}
      className={className}
    >
      <div className="mt-2">
        <h6 className="text-center font-weight-bold">{tipo?.msg}</h6>
      </div>
    </Modal>
  );
};

export default AlertModal;
