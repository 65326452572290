import React from "react";
import Modal from "../../../../template/components/Modal";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import ConfirmarForm from "../ConfirmarForm";
import { createGlobalStyle } from "styled-components";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  alterarSituacao: (row: any, data: any) => void;
}

interface IStyle {
  modalOpen: boolean;
}

// Retira a barra de rolagem do body após fechar o modal, mas mantém a barra com o modal aberto
const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

const ConfirmarModal = ({ row, isOpen, toggle, alterarSituacao }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="confirmarForm">
          <CustomAiOutlineCheck /> Confirmar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={"Confirmar família"}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <ConfirmarForm
        toggle={toggle}
        row={row}
        alterarSituacao={alterarSituacao}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default ConfirmarModal;
