import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import { Container } from "../../styles";
import { FormContainer } from "./style";

interface IProps {
  row: any;
  toggle: () => void;
  alterarSituacao: (row: any, data: any) => void;
}

type Inputs = {
  responsavel: string;
  situacao: string;
  observacao: string;
};

const TornarInaptaForm: React.FC<IProps> = (props) => {
  const { register, handleSubmit } = useForm<Inputs>();

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const onSubmit = (data: any) => {
    if (data.observacao === "") {
      notify("error", "A justificativa é obrigatória!");
      return;
    }
    props.alterarSituacao(props.row, data);
    props.toggle();
  };

  const [situacaoInapta, setSituacaoInapta] = useState<number>(0);

  useEffect(() => {
    const getSituacao = async () => {
      const { data } = await api.get("situacoes/");
      let situacaoInapta = data.find(
        (element: { situacao: string }) => element.situacao === "INAPTO"
      );
      setSituacaoInapta(situacaoInapta.id);
    };
    getSituacao();
  }, []);

  return (
    <Container>
      <FormContainer id="tornarInaptaForm" onSubmit={handleSubmit(onSubmit)}>
        <label>Responsável</label>
        <p>{props.row?.responsavel}</p>
        <input
          type="hidden"
          name="situacao"
          value={situacaoInapta}
          ref={register({ required: true })}
        />
        <label htmlFor="observacao">Justificativa</label>
        <textarea
          name="observacao"
          ref={register}
          style={{ padding: "10px" }}
          placeholder="Justifique o motivo da inaptidão dessa família"
        />
      </FormContainer>
    </Container>
  );
};

export default TornarInaptaForm;
