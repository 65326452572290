import React from "react";
import Modal from "../../../../template/components/Modal";

import {
  ButtonSave,
  ButtonClose,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import TornarInaptaForm from "../TornarInaptaForm";
import { createGlobalStyle } from "styled-components";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  alterarSituacao: (row: any, data: any) => void;
}

interface IStyle {
  modalOpen: boolean;
}

const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

const TornarInaptaModal = ({
  row,
  isOpen,
  toggle,
  alterarSituacao,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave type="submit" form="tornarInaptaForm">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      modalTitle={`Tornar a família inapta`}
      footerContent={footerButtons()}
      fixed={true}
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <TornarInaptaForm
        toggle={toggle}
        row={row}
        alterarSituacao={alterarSituacao}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default TornarInaptaModal;
