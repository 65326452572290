import React from "react";
import Card from "../../../../template/components/Card";
import { CardLine } from "./styles";

interface IProps {
  title: string;
  bovino: number;
  caprino: number;
}

const CardHomeGestorLocal = ({ title, bovino, caprino }: IProps) => {
  return (
    <>
      <Card title={title}>
        <>
          <CardLine>
            <p>
              Bovino: <span>{bovino}</span>
            </p>
          </CardLine>
          <CardLine>
            <p>
              Caprino: <span>{caprino}</span>
            </p>
          </CardLine>
          <CardLine>
            <p>
              Total: <span>{bovino + caprino}</span>
            </p>
          </CardLine>
        </>
      </Card>
    </>
  );
};

export default CardHomeGestorLocal;
