import React from "react";

import Modal from "../../../../../template/components/Modal";
import { FamiliaReadInterface } from "../../../../../template/utils/types";

import DetalharForm from "../DetalharForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import { createGlobalStyle } from "styled-components";

interface IStyle {
  modalOpen: boolean;
}

// Retira a barra de rolagem do body após fechar o modal, mas mantém a barra com o modal aberto
const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

interface IProps {
  familiaSelecionada: FamiliaReadInterface;
  isOpen: boolean;
  toggle: () => void;
}

const DetalharModal = ({ familiaSelecionada, isOpen, toggle }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        {/*
        <ButtonSave type="submit" form="cadastrarFormFamilia">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
        */}
      </React.Fragment>
    );
  };

  return familiaSelecionada ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={"Informações da familia"}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <DetalharForm familiaSelecionada={familiaSelecionada} toggle={toggle} />
    </Modal>
  ) : (
    <div />
  );
};

export default DetalharModal;
