import styled from "styled-components";
import colors from "../../../../../template/styles/colors";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  .error-span {
    color: red;
    font-weight: bold;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  label {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }

  .inputsGroup {
    display: flex;
    flex-direction: column;
  }

  .inputLocalidade {
    padding: 5px;
    resize: none;
  }

  .subtitleLocal {
    margin: 13px 0 13px 0;
  }

  .subtitleLocal h6 {
    font-size: 15px;
    font-weight: bold;
  }
`;
