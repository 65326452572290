export default [
  {
    name: "Página inicial",
    icon: "CustomAiOutlineHome",
    route: "/",
    hasSubMenu: false,
  },
  {
    name: "Listar todas as famílias",
    icon: "CustomFiUsers",
    route: "/confirmar",
    hasSubMenu: false,
  },
  {
    name: "Relatórios",
    icon: "CustomRiPagesLine",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Entregas do leite por Laticínio",
        route: "/relatorio_entregas_laticinio",
      },
      {
        name: "Distribuições do leite por Ponto",
        route: "/relatorio_distribuicoes_ponto",
      },
      {
        name: "Doações do leite",
        route: "/relatorio_doacoes",
      },
      {
        name: "Famílias Vinculadas",
        route: "/relatorio_familias_vinculadas",
      },
      {
        name: "Presença das famílias nas distribuições",
        route: "/relatorio_presenca",
      },
    ],
  },
];
