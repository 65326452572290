import axios from "axios";

import { getToken } from "./auth";

import authInterceptor from "./authInterceptor";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/"
      : "https://api.plp.sethas.rn.gov.br/api/",
});

/* const api = axios.create({
  baseURL: "http://localhost:8000/api/",
});
 */
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
    //Trata as mensagens de erro recebidas da API
  },
  function (error) {
    let newError = Object.assign({}, error);

    //Verifica se o error é do tipo 400
    if (newError.response.status === 400) {
      if (
        newError.response.headers["content-type"].includes(
          "application/problem+json"
        )
      ) {
        //Logica para passar as chaves dos erros para lowerCamelCase
        /* let serverData = newError.response.data;

      let errorsLowerCamelCase = Object.fromEntries(
          Object.entries(serverData.errors).map(([key, value]) =>
              [key, value]
          )
      ) */

        newError.response.data.hasValidationErrors = true;
        /* newError.response.data.errors = errorsLowerCamelCase; */
      }
    }
    return Promise.reject(newError);
  }
);

api.interceptors.response.use(undefined, authInterceptor(api));

export default api;
