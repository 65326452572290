import React from "react";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Confirmar from "./pages/Confirmar";

import Login from "./template/components/Login";

import RelatorioPresenca from "./pages/Relatorios/Presenca";
import { isAuthenticated } from "./services/auth";
import RelatorioEntregasLaticinio from "./pages/Relatorios/EntregasLaticinio";
import RelatorioDistribuicoesPontos from "./pages/Relatorios/DistribuicoesPontos";
import RelatorioDoacoes from "./pages/Relatorios/Doacoes";
import FamiliasVinculadas from "./pages/Relatorios/FamiliasVinculadas";
import Pontos from "./pages/Gerenciar/Pontos";
interface Iprops {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: Iprops) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Login backGroundImg="bgtest4" initialPagePath="/dashboard" />
          )}
        />

        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/confirmar" exact component={Confirmar} />
        <PrivateRoute
          path="/relatorio_entregas_laticinio"
          exact
          component={RelatorioEntregasLaticinio}
        />
        <PrivateRoute
          path="/relatorio_distribuicoes_ponto"
          exact
          component={RelatorioDistribuicoesPontos}
        />
        <PrivateRoute
          path="/relatorio_doacoes"
          exact
          component={RelatorioDoacoes}
        />
        <PrivateRoute
          path="/relatorio_familias_vinculadas"
          component={FamiliasVinculadas}
        />
        <PrivateRoute path="/gerenciar_pontos" component={Pontos} />
        <PrivateRoute
          path="/relatorio_presenca"
          component={RelatorioPresenca}
        />

        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
