import styled from "styled-components";
import colors from "../../../../template/styles/colors";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }
`;
