import React, { useState, useEffect, useCallback } from "react";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import CardHomeGestorLocal from "../CardHomeGestoLocal";
import {
  CardsRow,
  CardsRowTitle,
  IconRowTitle,
  CardFilter,
  CardFilterTitle,
  CardFilterSelect,
  CustomReactSelect,
} from "./styles";
import { CustomSpinner } from "./styles";
import { getUserToken } from "../../../../services/auth";

type PontosDataType = {
  id: number;
  nome: string;
  cota_leite_bovino: number;
  cota_leite_caprino: number;
};

type MunicipioDataType = {
  nome: string;
  total_cota_bovino: number;
  total_cota_caprino: number;
};

type EntidadeDataType = {
  municipio: {
    id: number;
    nome: string;
    total_cota_bovino: number;
    total_cota_caprino: number;
  };
};

type SelectOptionsType = {
  label: string;
  value: number;
};

const CardsComponent = () => {
  const [pontosData, setPontosData] = useState<PontosDataType[]>([]);
  const [municipioData, setMunicipioData] = useState<MunicipioDataType | null>(
    null
  );
  const [entidadeId, setEntidadeId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [municipios, setMunicipios] = useState<any[]>();
  const [municipiosOptions, setMunicipiosOptions] = useState<
    SelectOptionsType[]
  >();
  const [municipioSelected, setMunicipioSelected] = useState<number>(0);
  const user_is_gestor_estadual = getUserToken().is_gestor_estadual;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const getMunicipiosForSelect = async () => {
    try {
      const { data } = await api.get<EntidadeDataType[]>("municipios/");
      setMunicipios(data);
    } catch (err) {
      notify(
        "error",
        "Ocorreu um erro ao obter opções de municípios no filtro"
      );
    }
  };

  const getMunicipioDataCard = async () => {
    try {
      const {
        data: { nome, total_cota_bovino, total_cota_caprino },
      } = await api.get<MunicipioDataType>(`municipios/${municipioSelected}/`);
      setMunicipioData({
        nome,
        total_cota_bovino: total_cota_bovino ? total_cota_bovino : 0,
        total_cota_caprino: total_cota_caprino ? total_cota_caprino : 0,
      });
    } catch (err) {
      notify(
        "error",
        "Ocorreu um erro ao obter os dados do município para o card"
      );
    }
  };

  const getPontos = async () => {
    try {
      if (user_is_gestor_estadual && municipioSelected === 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }

      const {
        data: { results },
      } = await api.get(
        `${
          user_is_gestor_estadual
            ? `pontos/?municipio=${municipioSelected}`
            : "pontos/"
        }`
      );
      setPontosData(results);

      if (!user_is_gestor_estadual) {
        if (results.length) {
          setEntidadeId(results[0].entidade.id);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notify(
        "error",
        "Não foi possível carregar a quantidade de leite dos pontos"
      );
    }
  };

  useEffect(() => {
    if (municipios) {
      const options: SelectOptionsType[] = [
        { label: "Selecione um município", value: 0 },
      ];
      municipios?.map((municipio) =>
        options.push({ label: municipio.nome, value: municipio.id })
      );
      setMunicipiosOptions(options);
    }
  }, [municipios]);

  useEffect(() => {
    getPontos();
    if (municipioSelected) {
      getMunicipioDataCard();
    }
  }, [municipioSelected]);

  useEffect(() => {
    if (user_is_gestor_estadual) {
      getMunicipiosForSelect();
    }
  }, []);

  useEffect(() => {
    if (entidadeId) {
      (async function () {
        try {
          const {
            data: {
              municipio: { nome, total_cota_bovino, total_cota_caprino },
            },
          } = await api.get<EntidadeDataType>(
            `entidades_cooperadas/${entidadeId}/`
          );
          setMunicipioData({
            nome,
            total_cota_bovino: total_cota_bovino ? total_cota_bovino : 0,
            total_cota_caprino: total_cota_caprino ? total_cota_caprino : 0,
          });
        } catch (err) {
          notify(
            "error",
            "Ocorreu um erro ao obter os dados do município da entidade"
          );
        }
      })();
    }
  }, [entidadeId]);

  return (
    <>
      {user_is_gestor_estadual && (
        <CardFilter>
          <CardFilterTitle>
            <h6>Filtro de municípios</h6>
          </CardFilterTitle>
          <CardFilterSelect>
            <CustomReactSelect
              name="municipiosSelect"
              placeholder="Selecione um município"
              options={municipiosOptions}
              noOptionsMessage={() => "Nenhum município encontrado"}
              onChange={(e: any) => {
                setMunicipioSelected(e.value);
              }}
            />
          </CardFilterSelect>
        </CardFilter>
      )}
      {(!user_is_gestor_estadual ||
        (user_is_gestor_estadual && municipioSelected > 0)) && (
        <>
          <CardsRowTitle>
            <IconRowTitle />
            {user_is_gestor_estadual ? (
              <h1>Cota de leite do município</h1>
            ) : (
              <h1>Cota de leite do município da entidade</h1>
            )}
          </CardsRowTitle>
          <CardsRow>
            {isLoading ? (
              <CustomSpinner />
            ) : municipioData ? (
              <CardHomeGestorLocal
                title={municipioData?.nome}
                bovino={municipioData?.total_cota_bovino}
                caprino={municipioData?.total_cota_caprino}
              />
            ) : (
              ""
            )}
          </CardsRow>
          <CardsRowTitle>
            <IconRowTitle />
            {user_is_gestor_estadual ? (
              <h1>Cota de leite dos pontos de distribuição do município</h1>
            ) : (
              <h1>Cota de leite dos pontos de distribuição da entidade</h1>
            )}
          </CardsRowTitle>
          <CardsRow>
            {isLoading ? (
              <CustomSpinner />
            ) : pontosData.length ? (
              pontosData.map(
                ({ id, nome, cota_leite_bovino, cota_leite_caprino }) => (
                  <CardHomeGestorLocal
                    key={id}
                    title={nome}
                    bovino={cota_leite_bovino}
                    caprino={cota_leite_caprino}
                  />
                )
              )
            ) : (
              ""
            )}
          </CardsRow>
        </>
      )}
    </>
  );
};

export default CardsComponent;
