import React, { useState, useEffect, useCallback } from "react";

import { dialogBox } from "../../template/utils/dialogBox";

import { parseJsonToTableDataList } from "../../template/utils/parser";

import MainContainer from "../../template/components/MainContainer";
import Layout from "../../template/components/Layout";
import Table from "../../template/components/Table";
import Pagination from "../../template/components/Pagination";
import SearchBar from "../../template/components/SearchBar";

import TornarInaptaModal from "./components/TornarInaptaModal";
import ConfirmarModal from "./components/ConfirmarModal";

import { FiUsers } from "react-icons/fi";

import {
  Container,
  CustomIconConfirmar,
  CustomIconDesvincular,
  CustomReactSelect,
  CustomSpinner,
} from "./styles";

import DocumentPDF from "./components/DocumentPDF";
import PdfModal from "./components/PdfModal";
import { saveAs } from "file-saver";

import api from "../../services/api";
import { parseJwt } from "../../services/auth";
import Button from "../../template/components/Button";
import { MdPictureAsPdf } from "react-icons/md";
import { pdf } from "@react-pdf/renderer";

const fields = [
  "Responsável",
  "Código Familiar",
  "Situação",
  "Renda",
  "Tipo",
  "Ações",
];

type selectType = {
  name: string;
  value: number;
};

type EntidadeDataType = {
  municipio: {
    id: number;
    nome: string;
  };
};

const Confirmar = () => {
  const [familias, setFamilias] = useState<any[]>([]);
  const [listaFamilias, setListaFamilias] = useState<any[]>([]);
  const [selectedFamilia, setSelectedFamilia] = useState<any>(null);
  const [modalAlterar, setmodalAlterar] = useState(false);
  const [modalConfirmar, setmodalConfirmar] = useState(false);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [downloadingPdfPontos, setDownloadingPdfPontos] = useState<boolean>(
    false
  );
  // Situações
  const [situacoes, setSituacoes] = useState<any[]>([]);
  const [situacaoAnalise, setSituacaoAnalise] = useState<number>(0);
  const [situacaoConfirmada, setSituacaoConfirmada] = useState<number>(0);
  const [situacaoInapta, setSituacaoInapta] = useState<number>(0);
  const [situacaoSelected, setSituacaoSelected] = useState<number>(0);
  const [situacaoOptions, setSituacaoOptions] = useState<any>([]);
  // Pontos
  const [pontos, setPontos] = useState<any[]>([]);
  const [pontosData, setPontosData] = useState<any[]>([]);
  const [pontoSelected, setPontoSelected] = useState<selectType>({
    name: "",
    value: 0,
  });
  const [pontoOptions, setPontoOptions] = useState<any>([]);
  const [selectedPontoOption, setSelectedPontoOption] = useState<any>([]);
  // Entidade
  const [entidadeId, setEntidadeId] = useState<number>(0);
  // Municípios
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<selectType>({
    name: "",
    value: 0,
  });
  const [municipioOptions, setMunicipioOptions] = useState<any>([]);
  //paginação
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const itemsPerPage = 10;
  const [searchedValue, setSearch] = useState<string>("");
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const loadFamilias = useCallback(() => {
    if (situacoes !== undefined) {
      let municipioParam =
        municipioSelected?.value === 0
          ? ""
          : municipioSelected.value.toString();
      let situacaoParam = situacaoSelected === 0 ? "" : situacaoSelected;
      let pontoParam = pontoSelected.value === 0 ? "" : pontoSelected.value;

      // Definindo as situações que devem ser listadas
      let situacoesId = "";
      situacoes.map((s) => {
        situacoesId += s.id?.toString() + ",";
      });
      situacoesId = situacoesId.replace(/,\s*$/, "");

      api
        .get(
          `familias/?limit=${itemsPerPage}&offset=${offset}&municipio=${municipioParam}&situacao=${situacaoParam}&situacoes=${situacoesId}&ponto=${pontoParam}&search=${searchedValue}&ordering=renda_percapita`
        )
        .then((data) => {
          const { results, count } = data.data;
          setFamilias(
            parseJsonToTableDataList(parseFamilias(results), [
              "responsavel",
              "cod_familia",
              "situacao",
              "renda_percapita",
              "tipo_leite",
            ])
          );
          setTotal(count);
        })
        .catch((error) => {
          if (error.response.data.municipio) {
            notify("error", `${error.response.data.municipio[0]}`);
          } else notify("error", "Erro no servidor");

          console.log(error);
          notify("error", "Erro no servidor");
        });
    }
  }, [
    offset,
    searchedValue,
    municipioSelected,
    situacaoSelected,
    situacoes,
    pontoSelected,
    notify,
  ]);

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada;

        if (parseJwt(decodeJWT()).is_gestor_estadual) {
          chamada = `pontos/?municipio=${municipioSelected.value}`;
        } else {
          chamada = `pontos/`;
        }

        const {
          data: { results },
        } = await api.get(chamada);

        if (!parseJwt(decodeJWT()).is_gestor_estadual) {
          if (results.length) {
            setEntidadeId(results[0].entidade.id);
          }
        }

        setPontos(results);
      } catch (error) {
        notify("error", "Ocorreu um erro ao carregar pontos");
      }
    };

    loadPontos();
  }, [municipioSelected]);

  useEffect(() => {
    if (entidadeId) {
      (async function () {
        try {
          const {
            data: {
              municipio: { id, nome },
            },
          } = await api.get<EntidadeDataType>(
            `entidades_cooperadas/${entidadeId}/`
          );
          setMunicipioSelected({ name: nome, value: id });
        } catch (err) {
          notify(
            "error",
            "Ocorreu um erro ao obter os dados do município da entidade"
          );
        }
      })();
    }
  }, [entidadeId]);

  function parseFamilias(familia: any[]) {
    let tmp = [];
    for (var i = 0; i < familia.length; i++) {
      const object = {
        id: familia[i].id,
        cod_familia: familia[i].cod_familia,
        situacao: familia[i].situacao,
        responsavel: familia[i].responsavel.nome,
        renda_percapita: familia[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        tipo_leite: familia[i].tipo_leite,
      };
      tmp.push(object);
    }
    return tmp;
  }

  useEffect(() => {
    const getMunicipios = async () => {
      const { data } = await api.get("municipios/");
      setMunicipios(data);
    };
    getMunicipios();
  }, []);

  useEffect(() => {
    const getSituacao = async () => {
      const { data } = await api.get("situacoes/");
      let situacaoAnalise = data.find(
        (element: { situacao: string }) => element.situacao === "EM ANÁLISE"
      );
      let situacaoConfirmada = data.find(
        (element: { situacao: string }) => element.situacao === "CONFIRMADA"
      );
      let situacaoInapta = data.find(
        (element: { situacao: string }) => element.situacao === "INAPTO"
      );
      setSituacaoAnalise(situacaoAnalise.id);
      setSituacaoConfirmada(situacaoConfirmada.id);
      setSituacaoInapta(situacaoInapta.id);
      setSituacoes([situacaoAnalise, situacaoConfirmada]);
    };
    getSituacao();
  }, []);

  useEffect(() => {
    loadFamilias();
  }, [loadFamilias]);

  const toggleTornarInaptaModal = () => {
    setmodalAlterar(!modalAlterar);
  };

  const tornarInapta = (row: any) => {
    toggleTornarInaptaModal();
    setSelectedFamilia(row);
  };

  const toggleConfirmarModal = () => {
    setmodalConfirmar(!modalConfirmar);
  };

  const toggleModalPdf = () => {
    setModalPdf(!modalPdf);
  };

  const confirmarSituacao = (row: any) => {
    toggleConfirmarModal();
    setSelectedFamilia(row);
  };

  const getListaFamilias = async (startPage: number, endPage: number) => {
    if (municipioSelected?.value > 0) {
      // Checa se o maior valor passado no modal de geração de pdf é maior que o o número da paginação final da listagem de famílias
      if (
        startPage > Math.ceil(total / itemsPerPage) ||
        endPage > Math.ceil(total / itemsPerPage)
      ) {
        notify(
          "error",
          "Um dos valores informados é maior que o valor final da paginação da lista"
        );
        return;
      }

      setStartPage(startPage);
      setEndPage(endPage);

      const calcEndPage = endPage - startPage;

      toggleModalPdf();
      setDownloadingPdfPontos(true);

      if (situacoes) {
        let situacaoParam = situacaoSelected === 0 ? "" : situacaoSelected;

        let situacoesId = "";
        situacoes.map((s) => {
          situacoesId += s.id?.toString() + ",";
        });
        situacoesId = situacoesId.replace(/,\s*$/, "");

        try {
          const {
            data: { results },
          } = await api.get(`familias/?limit=${
            calcEndPage === 0
              ? itemsPerPage
              : calcEndPage * itemsPerPage + itemsPerPage
          }
          &offset=${(startPage - 1) * itemsPerPage}&municipio=${
            municipioSelected.value
          }&situacao=${situacaoParam}&situacoes=${situacoesId}&ponto=${
            pontoSelected.value > 0 ? pontoSelected.value : ""
          }&search=${searchedValue}&ordering=responsavel`);

          const parsedData = parseFamiliasDocument(results);

          setListaFamilias(parsedData);
        } catch (err) {
          console.log(err);
          notify(
            "error",
            "Ocorreu um erro ao obter a lista de famílias vinculadas para pdf"
          );
          setDownloadingPdfPontos(false);
        }
      }
    }
  };

  const parseFamiliasDocument = (familias: any[]) => {
    const arrListaFamilias: any[] = [];

    if (pontoSelected.value === 0) {
      pontos.map((ponto) => {
        ponto.familias = [];
      });

      pontos.map((ponto, index) => {
        const familiasVinculadas = familias.filter(
          (fam) => ponto.nome === fam.ponto.nome
        );
        familiasVinculadas.map((famV) => {
          pontos[index].familias.push(familiaObject(famV));
          arrListaFamilias.push(famV);
        });
      });

      setPontosData(pontos);
    } else {
      familias.map((familia) => {
        arrListaFamilias.push(familiaObject(familia));
      });
    }
    return arrListaFamilias;
  };

  const familiaObject = (familia: any) => {
    return {
      responsavel: familia.responsavel.nome,
      cod_familia: familia.cod_familia,
      renda: familia.renda_percapita.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      tipo: familia.tipo,
      tipo_leite: familia.tipo_leite,
      localidade: familia.endereco.localidade.nome,
      municipio: familia.endereco.municipio.nome,
      ponto: familia.ponto.nome,
      situacao: familia.situacao,
    };
  };

  useEffect(() => {
    if (listaFamilias.length !== 0) {
      createPdf();
    }
  }, [listaFamilias]);

  const createPdf = async () => {
    try {
      const blob = await pdf(
        <DocumentPDF
          data={listaFamilias}
          count={total}
          totalMunicipio={total}
          municipio={municipioSelected?.name ? municipioSelected.name : ""}
          pontoNome={pontoSelected.name}
          selectedPonto={pontoSelected.value}
          pontos={pontosData}
          startPage={startPage}
          endPage={endPage}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      saveAs(
        blob,
        `familias_vinculadas_${municipioSelected.name}_${pontoSelected.name}_${datenow}.pdf`
      );
      setDownloadingPdfPontos(false);
    } catch (err) {
      console.log(err);
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloadingPdfPontos(false);
    }
  };

  const alterarSituacaoFamilia = (row: any, data: any) => {
    const alterar = async () => {
      try {
        if (data.situacao !== situacaoAnalise) {
          await api.patch(`/familias/${row.id}/`, {
            situacao: data.situacao,
            observacao: data.observacao,
          });
          data.situacao == situacaoInapta
            ? notify("success", "Família tornada inapta")
            : notify("success", "Família confirmada");
          loadFamilias();
        } else {
          notify("", "Sem alterações");
        }
      } catch (err) {
        notify("error", `${err}`);
      }
    };
    alterar();
  };

  // Paginação

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + 10;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - 10;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        10 -
        10
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  const decodeJWT = () => {
    var token: any;
    token = localStorage.getItem("accessToken");

    if (token) {
      let aux = "";
      aux = token;
      return aux;
    }
    return "";
  };

  useEffect(() => {
    const options: any = [{ label: "Todos", value: 0 }];
    municipios?.map((municipio) =>
      options.push({ value: municipio.id, label: municipio.nome })
    );
    setMunicipioOptions(options);
  }, [municipios]);

  useEffect(() => {
    const options: any = [{ label: "Todas", value: 0 }];
    situacoes?.map((situacao) =>
      options.push({ value: situacao.id, label: situacao.situacao })
    );
    setSituacaoOptions(options);
  }, [situacoes]);

  useEffect(() => {
    const options: any = [{ label: "Todos", value: 0 }];
    pontos?.map((ponto) =>
      options.push({ value: ponto.id, label: ponto.nome })
    );
    setPontoOptions(options);
  }, [pontos]);

  const search = useCallback((searchedValue: string) => {
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer titlePage={"Confirmar famílias"} iconPage={<FiUsers />}>
        <TornarInaptaModal
          isOpen={modalAlterar}
          toggle={toggleTornarInaptaModal}
          row={selectedFamilia}
          alterarSituacao={alterarSituacaoFamilia}
        />
        <ConfirmarModal
          isOpen={modalConfirmar}
          toggle={toggleConfirmarModal}
          row={selectedFamilia}
          alterarSituacao={alterarSituacaoFamilia}
        />
        <PdfModal
          isOpen={modalPdf}
          toggle={toggleModalPdf}
          className="modal-md"
          getFamilias={(startPage: number, endPage: number) =>
            getListaFamilias(startPage, endPage)
          }
          endPageDefault={Math.ceil(total / itemsPerPage)}
        />
        <Container>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
            </div>
            <div className="card-body">
              <div className="form-row">
                {parseJwt(decodeJWT()).is_gestor_estadual && (
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomReactSelect
                      name="municipiosSelect"
                      className="space select"
                      placeholder="Todos"
                      options={municipioOptions}
                      onChange={(e: any) => {
                        setMunicipioSelected({
                          name: e.label,
                          value: parseInt(e.value),
                        });
                        setSelectedPontoOption({ value: 0, label: "Todos" });
                        setPontoSelected({ name: "", value: 0 });
                        first();
                      }}
                      noOptionsMessage={() => "Nenhum município encontrado"}
                    />
                  </div>
                )}
                <div className="form-group col">
                  <label>Situação</label>
                  <CustomReactSelect
                    name="situacaoSelect"
                    className="space select"
                    placeholder="Todas"
                    options={situacaoOptions}
                    onChange={(e: any) => {
                      setSituacaoSelected(e.value);
                      first();
                    }}
                    noOptionsMessage={() => "Nenhuma situação encontrada"}
                  />
                </div>
                <div className="form-group col">
                  <label>Ponto</label>
                  <CustomReactSelect
                    name="pontoSelect"
                    className="space select"
                    placeholder="Todos"
                    value={selectedPontoOption}
                    options={pontoOptions}
                    onChange={(e: any) => {
                      setSelectedPontoOption({
                        value: parseInt(e.value),
                        label: e.label,
                      });
                      setPontoSelected({
                        name: e.label,
                        value: parseInt(e.value),
                      });
                      first();
                    }}
                    noOptionsMessage={() => "Nenhum ponto encontrado"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="searchBar space">
            <SearchBar
              placeholder="Nome do responsável"
              submitHandler={search}
            />
          </div>

          <Table
            fields={fields}
            rows={familias}
            hasSelection={false}
            hasSelectionAll={false}
            functions={[
              {
                iconBtn: <CustomIconDesvincular />,
                name: "Tornar inapta",
                action: tornarInapta,
              },
              {
                iconBtn: <CustomIconConfirmar />,
                name: "Confirmar",
                action: confirmarSituacao,
              },
            ]}
          />
          <div className="bottom-buttons">
            {municipioSelected?.value > 0 && familias.length > 0 && (
              <>
                <Button
                  onClick={() => {
                    if (!downloadingPdfPontos) {
                      toggleModalPdf();
                    }
                  }}
                  name={"PDF famílias vinculadas ao ponto"}
                  color={downloadingPdfPontos ? "grey" : "blue"}
                  iconButtom={<MdPictureAsPdf />}
                />
                {downloadingPdfPontos && <CustomSpinner />}
              </>
            )}
          </div>
          <div className="row">
            Visualizando {familias.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Confirmar;
