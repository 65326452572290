import styled from "styled-components";
import colors from "../../../../template/styles/colors";
import { AiOutlineBarChart } from "react-icons/ai";
import { Spinner } from "reactstrap";
import Select from "react-select";

export const CardsRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 15px 0 15px 0;
  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardsRowTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: ${colors.ultraDarkGrey};
  border-radius: 8px;
  color: white;
  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
  padding-left: 10px;
  height: 35px;
`;

export const CardFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${colors.lightGrey};
  margin-bottom: 15px;
  border-radius: 8px;
`;

export const CardFilterTitle = styled.div`
  h6 {
    margin: 0;
    font-weight: bold;
  }
`;

export const CardFilterSelect = styled.div`
  display: flex;
`;

export const CustomReactSelect = styled(Select)`
  width: 200px;
`;

export const IconRowTitle = styled(AiOutlineBarChart)`
  margin-right: 5px;
  font-size: 20px;
`;

export const CustomSpinner = styled(Spinner)`
  font-size: 12px;
`;
